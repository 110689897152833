import React, { forwardRef, useState } from "react";
import {
  createSpinner,
  showSpinner,
  hideSpinner,
} from "@syncfusion/ej2-popups";
import {
  Month,
  DragAndDrop,
  ScheduleComponent,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
  MonthAgenda,
  ICalendarExport,
  Print,
  ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import {
  Typography,
  IconButton,
  Button,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import Person from "@mui/icons-material/Person";
import { isSameDay, format, isBefore, isAfter } from "date-fns";
import "../../AllStyles/Calendar.css";
import { userVar, ManualEventsToSave } from "../../cache";
import ShiftSlack from "../slacks/ShiftSlack";
import { makeStyles } from "@mui/styles";
import {
  ShiftTemplate,
  ShiftAssignmentTemplate,
  EmployeeAvailabilityTemplate,
  OnCallTemplate,
  SoftRequestTemplate,
} from "./EventTemplate";
import {
  getLocalStorageItem,
  useStatelessSessionStorage,
} from "../../hooks/storage/useStorage";
import {
  formatShortTime as localShortTime,
  formatMilitaryTime as localMilitaryTime,
} from "../../helpers/formatTime";
import { closest, addClass, createElement } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-react-dropdowns";
import SkillColors from "../../helpers/SkillColors.json";
import EmployeeGrid from "./EmployeeGrid";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { convertHex } from "../../helpers/myColors";
import { filterDuplicates } from "./filterDuplicates";
import { applyFilters, applyOfficeFilters } from "./filter/FilterEvents";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 5,
    paddingTop: 0,
  },
  today: {
    background: "#D0D0D0",
    color: "red",
    borderRadius: 30,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tooltip: {
    minWidth: 600,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
}));

const Scheduler = forwardRef((props, ref) => {
  const {
    officeResources,
    employeeResources,
    queryHandler,
    schedulePeriods,
    toggleShiftSwitchForm,
    toggleCallOffForm,
    managerAccess,
    draftStart,
    draftEnd,
    openStart,
    openEnd,
    shiftResources,
    skills,
    toggleEditRequest,
    toggleCallInForm,
    CMO,
    createManualShiftAssignments,
    officeConstraints,
    selectedOffice,
    setQuickInfoProp,
  } = props;

  employeeResources.length > 0 &&
    employeeResources.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  let allShiftResources =
    shiftResources.length > 0
      ? [
          ...shiftResources
            .map((e) => {
              return {
                ...e,
                name: e.description,
              };
            })
            .sort((a, b) => {
              if (a.office.name < b.office.name) {
                return -1;
              }
              if (a.office.name > b.office.name) {
                return 1;
              }
              return 0;
            }),
          {
            id: "OnCall",
            name: "On Call",
          },
          {
            id: "Requests",
            name: "Requests",
          },
        ]
      : [];
  const [currentDate, setCurrentDate] = useStatelessSessionStorage(
    "currentDate",
    new Date(),
  );
  const [currentView, setCurrentView] = useStatelessSessionStorage(
    "currentView",
    "TimelineDay",
  );
  const user = userVar();
  const classes = useStyles();
  const allowCallIns = user.office.allowCallIns;
  let localStorageView = localStorage.getItem("schedule");
  localStorageView = localStorageView && JSON.parse(localStorageView);
  const [displayEmployeeGrid, SetDisplayEmployeeGrid] = useState(
    localStorageView &&
      localStorageView.currentView !== null &&
      localStorageView.currentView === "TimelineWeek"
      ? true
      : false,
  );

  const manualEvents = ManualEventsToSave();
  const onTreeDragStop = (event) => {
    const scheduleObj = ref.current;
    const newEvents = [];
    let treeElement = closest(event.target, ".e-treeview");
    let classElement = document.querySelector(".e-device-hover");
    if (classElement) {
      classElement.classList.remove(".e-device-hover");
    }
    if (!treeElement) {
      event.cancel = true;
      let scheduleElement = closest(event.target, ".e-content-wrap");
      if (scheduleElement) {
        let treeviewData = employeeResources;
        if (event.target.classList.contains("e-work-cells")) {
          const filteredData = treeviewData.filter(
            (item) =>
              parseInt(item.id) === parseInt(event.draggedNodeData.id, 10),
          );

          let cellData = scheduleObj.getCellDetails(event.target);
          let resourceDetails = scheduleObj.getResourcesByIndex(
            cellData.groupIndex,
          );
          let uniqueShifts = [];
          let eventsForSelectedDate = scheduleObj.getEvents(
            cellData.startTime,
            cellData.endTime,
          );
          eventsForSelectedDate &&
            eventsForSelectedDate.length > 0 &&
            eventsForSelectedDate
              .filter(
                (e) => e.shiftResource === resourceDetails.resourceData.id,
              )
              .map((e) => {
                if (!uniqueShifts.find((x) => x.shiftId === e.shiftId)) {
                  uniqueShifts.push(e);
                }
              });

          let schedulePresent =
            schedulePeriods &&
            schedulePeriods.length > 0 &&
            schedulePeriods.find(
              (e) =>
                e.start <= format(new Date(cellData.startTime), "yyyy-MM-dd") &&
                e.end >= format(new Date(cellData.endTime), "yyyy-MM-dd"),
            );
          if (!schedulePresent && user.isEmployee === true) {
            toastShow(
              "There are no Schedules found for this selected date.",
              "Warning",
            );
          } else if (!schedulePresent && user.isEmployee !== true) {
            toastShow(
              "Manual scheduling not allowed for this date.",
              "Warning",
            );
          } else if (
            schedulePresent &&
            (resourceDetails.resourceData.id === "OnCall" ||
              resourceDetails.resourceData.id === "Requests")
          ) {
            toastShow(
              "Please drop the employee to a shift resource.",
              "Warning",
            );
          } else if (
            schedulePresent != null &&
            schedulePresent.status !== "OPEN" &&
            user.isEmployee === true
          ) {
            toastShow(
              "Manual scheduling not allowed for this date.",
              "Warning",
            );
          } else if (
            schedulePresent != null ||
            schedulePresent !== "undefined"
          ) {
            if (
              schedulePresent &&
              (schedulePresent.status === "PUBLISHED" ||
                schedulePresent.status === "DRAFT" ||
                format(new Date(), "yyyy-MM-dd") >
                  format(new Date(cellData.startTime), "yyyy-MM-dd"))
            ) {
              toastShow(
                "Manual scheduling not allowed for this date.",
                "Warning",
              );
            } else if (
              (schedulePresent && schedulePresent.status === "OPEN") ||
              schedulePresent.status === "READY" ||
              schedulePresent.status === "MANAGER_DRAFT"
            ) {
              let shiftStart = resourceDetails.resourceData.start;
              let shiftEnd = resourceDetails.resourceData.end;
              let uniqueSkills = [];

              officeConstraints &&
                officeConstraints.length > 0 &&
                officeConstraints
                  .filter(
                    (e) =>
                      e.type.name === "SKILLCOVER" &&
                      e.starttime === shiftStart &&
                      e.endtime === shiftEnd &&
                      e.office.name ===
                        resourceDetails.resourceData.office.name,
                  )
                  .map(
                    (e) =>
                      !uniqueSkills.includes(parseInt(e.skill.id)) &&
                      uniqueSkills.push(parseInt(e.skill.id)),
                  );
              let formElement = document.querySelector(".e-schedule-form");

              if (document.querySelector(".custom-field-row")) {
                let customField = formElement.querySelector(
                  ".custom-field-container",
                );
                customField.removeChild(customField.firstChild);
              }
              let row = createElement("div", {
                className: "custom-field-row",
              });
              formElement.firstChild.insertBefore(
                row,
                formElement.firstChild.firstChild,
              );
              let container = createElement("div", {
                className: "custom-field-container",
              });
              let inputEle = createElement("input", {
                className: "e-field",
                attrs: { name: "Shift" },
              });
              container.appendChild(inputEle);
              row.appendChild(container);

              let dropDownList = new DropDownList({
                dataSource:
                  uniqueShifts.length > 0 &&
                  uniqueShifts.filter(Boolean).length > 0 &&
                  uniqueShifts.filter(Boolean).map((e) => ({
                    id: e.shiftId,
                    name:
                      localShortTime(e.start, e.end) +
                      " " +
                      format(e.start, "MMM-dd"),
                  })),
                fields: {
                  text: "name",
                  value: "id",
                  validation: { required: true },
                },
                value: formElement.Shift,
                change: dropDownChange,
                placeholder: "Shift",
              });

              dropDownList.appendTo(inputEle);
              inputEle.setAttribute("name", "Shift");
              let eventData = {
                name: filteredData[0].name,
                isAllDay: cellData.isAllDay,
                start: new Date(cellData.startTime).toISOString(),
                end: new Date(cellData.endTime).toISOString(),
                subject: filteredData[0].name,
                employeeId: filteredData[0].id,
                requiredSkills: uniqueSkills,
                skillNames: filteredData[0].skills,
                shiftResource: resourceDetails.resourceData.id,
                officeName: resourceDetails.resourceData.office.name,
                Shift: uniqueShifts.length === 1 && uniqueShifts[0].shiftId,
              };

              scheduleObj.openEditor(eventData, "Add", true);
              scheduleObj.eventWindow.recurrenceEditor.frequencies = [
                "none",
                "daily",
                "weekly",
              ];

              var buttonElement = ".e-schedule-dialog .e-event-save";
              var saveButton = document.querySelector(buttonElement);
              let shiftValue = dropDownList.inputElement.value;

              if (shiftValue === "") {
                saveButton.ej2_instances[0].disabled = true;
              }
              let end =
                document.querySelector(".e-end-on-element").ej2_instances[0];
              end.dataSource = [{ text: "Never", value: "never" }];
              end.dataBind();
              newEvents.push(
                manualEvents && manualEvents.length > 0
                  ? [...manualEvents, eventData]
                  : eventData,
              );
              ManualEventsToSave(newEvents.flat());
            }
          }
        }
      }
    }
  };

  const dropDownChange = (args) => {
    var buttonElement = ".e-schedule-dialog .e-event-save";
    var saveButton = document.querySelector(buttonElement);
    if (args.value !== "") {
      saveButton.ej2_instances[0].disabled = false;
    }
  };

  const manualSchedulingSave = (args) => {
    if (args.type === "Editor" && args.data != null) {
      ref.current.showSpinner();
      ManualEventsToSave().map((ev) => {
        if (args.data.RecurrenceRule != null) {
          createManualShiftAssignments({
            variables: {
              employee: parseInt(ev.employeeId),
              shift: parseInt(args.data.Shift),
              skills: ev.requiredSkills,
              recurrence: args.data.RecurrenceRule,
            },
          });
        } else {
          createManualShiftAssignments({
            variables: {
              employee: parseInt(ev.employeeId),
              shift: parseInt(args.data.Shift),
              skills: ev.requiredSkills,
            },
          });
        }
      });
    }
  };

  const onItemDrag = (event) => {
    const scheduleObj = ref.current;
    let treeElement = document.querySelector(".e-treeview").ej2_instances[0];
    treeElement.dragArea = ".e-schedule";

    //treeElement.dragObj.dragArea = "body";
    if (scheduleObj.isAdaptive) {
      let classElement = scheduleObj.element.querySelector(".e-device-hover");
      if (classElement) {
        classElement.classList.remove("e-device-hover");
      }
      if (event.target.classList.contains("e-work-cells")) {
        addClass([event.target], "e-device-hover");
      }
    }
    if (document.body.style.cursor === "not-allowed") {
      document.body.style.cursor = "";
    }
    if (event.name === "nodeDragging") {
      let dragElementIcon = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag .e-icon-expandable",
      );
      for (let i = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = "none";
      }
    }
  };

  const onEventRendered = (args) => {
    if (
      args.data.eventType === "SHIFTASSIGNMENT" &&
      (window.TENANT_NAME === "evarahealth" ||
        window.TENANT_NAME === "demo" ||
        window.TENANT_NAME === "staging" ||
        window.TENANT_NAME === "dev")
    ) {
      let color = SkillColors.skills.find((e) =>
        args.data.employee.skillSet.find((s) => s.name === e.name),
      )
        ? SkillColors.skills.find((e) =>
            args.data.employee.skillSet.find((s) => s.name === e.name),
          ).color
        : "#595A5C";
      args.element.style.background = convertHex(color, "0.25");
      args.element.style.border = args.data.callIn
        ? "4px solid" + " " + "#ff4d4d"
        : "2px solid" + " " + color;
      args.element.style.height = "29px";
      args.element.style.verticalAlign = "middle";
    } else {
      args.element.style.background = convertHex("#595A5C", "0.25");
      args.element.style.border = args.data.callIn
        ? "4px solid" + " " + "#ff4d4d"
        : "2px solid" + " " + "#595A5C";
      args.element.style.height = "29px";
      args.element.style.verticalAlign = "middle";
    }
  };

  const handleNavigation = () => {
    showSpinner(document.getElementById("schedule"));
    queryHandler(ref.current)
      .then((result) => {
        // Process schedule data
        const scheduleEvents = result.scheduleData.flatMap(
          (promise) => promise.value,
        );
        const timeOffData = result.timeOffData.flatMap(
          (promise) => promise.value,
        );
        const timeOffEventsSet = filterDuplicates(timeOffData); // potentially obselete with changes
        ref.current.allScheduleDataObject = {};
        scheduleEvents.forEach((event) => {
          ref.current.allScheduleDataObject[event.id] = event;
        });
        timeOffEventsSet.forEach((event) => {
          ref.current.allScheduleDataObject[event.id] = event;
        });
        const allScheduleData = Object.values(
          ref.current.allScheduleDataObject,
        );
        let displayedEvents;
        if (ref.current) {
          displayedEvents = applyOfficeFilters(
            selectedOffice.name,
            allScheduleData,
          );
        } else {
          displayedEvents = allScheduleData;
        }

        // Process slack data
        var slackEvents = {};
        for (var slack of result.slackData) {
          slackEvents = { ...slackEvents, ...slack.value };
        }
        ref.current.slackEvents = {
          ...ref.current.slackEvents,
          ...slackEvents,
        };

        ref.current.eventSettings.dataSource.length = 0;
        ref.current.addEvent(displayedEvents);
        ref.current.refreshTemplates("dateHeaderTemplate");
        hideSpinner(document.getElementById("schedule"));
        // if any promise was rejected show toast?
      })
      .catch((err) => {
        hideSpinner(document.getElementById("schedule"));
        console.log(err);
        // show toast?
      });
  };

  const onCreated = () => {
    createSpinner({ target: document.getElementById("schedule") });
    if (ref?.current && !ref.current.slackEvents) {
      ref.current.slackEvents = {};
    }
    if (currentView === "TimelineWeek") {
      SetDisplayEmployeeGrid(true);
    }
    if (currentView === "Employee Timeline") {
      ref.current.changeCurrentView("TimelineDay", 1);
    } else {
      handleNavigation();
    }
  };

  const onActionComplete = (args) => {
    if (args.requestType === "viewNavigate") {
      if (ref.current.getCurrentViewIndex() === 1) {
        setCurrentView("Employee Timeline");
        SetDisplayEmployeeGrid(false);
      } else {
        setCurrentView(ref.current.currentView);
        if (ref.current.currentView === "TimelineWeek") {
          SetDisplayEmployeeGrid(true);
        } else {
          SetDisplayEmployeeGrid(false);
        }
      }
    }
    if (
      args.requestType === "dateNavigate" ||
      args.requestType === "viewNavigate"
    ) {
      handleNavigation();
      setCurrentDate(ref.current.getCurrentViewDates()[0]);
      let currentViewDates = ref.current.getCurrentViewDates();
    }
    ref.current.refreshLayout();
  };

  const shiftTimelineTemplate = (props) => {
    switch (props.eventType) {
      case "SHIFT":
        return (
          <ShiftTemplate
            props={props}
            view="Shift Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "SHIFTASSIGNMENT":
        return (
          <ShiftAssignmentTemplate
            props={props}
            view="Shift Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "EMPLOYEEAVAILABILITY":
        return (
          <EmployeeAvailabilityTemplate
            props={props}
            view="Shift Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "ONCALL":
        return (
          <OnCallTemplate
            props={props}
            view="Shift Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "OFFPREFERENCE":
        return (
          <SoftRequestTemplate
            props={props}
            view="Shift Timeline"
            env={window}
            cmo={CMO}
          />
        );
    }
  };

  const employeeTimelineTemplate = (props) => {
    switch (props.eventType) {
      case "SHIFTASSIGNMENT":
        return (
          <ShiftAssignmentTemplate
            props={props}
            view="Employee Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "EMPLOYEEAVAILABILITY":
        return (
          <EmployeeAvailabilityTemplate
            props={props}
            view="Employee Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "ONCALL":
        return (
          <OnCallTemplate
            props={props}
            view="Employee Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "OFFPREFERENCE":
        return (
          <SoftRequestTemplate
            props={props}
            view="Employee Timeline"
            env={window}
            cmo={CMO}
          />
        );
      case "SHIFT":
        return (
          <ShiftTemplate
            props={props}
            view="Employee Timeline"
            env={window}
            cmo={CMO}
          />
        );
    }
  };

  const manualSchedulingTemplate = (props) => {
    switch (props.eventType) {
      case "SHIFTASSIGNMENT":
        return (
          <ShiftAssignmentTemplate
            props={props}
            view="Manual Scheduling"
            env={window}
            cmo={CMO}
          />
        );
      case "EMPLOYEEAVAILABILITY":
        return (
          <EmployeeAvailabilityTemplate
            props={props}
            view="Manual Scheduling"
            env={window}
            cmo={CMO}
          />
        );
      case "ONCALL":
        return (
          <OnCallTemplate
            props={props}
            view="Manual Scheduling"
            env={window}
            cmo={CMO}
          />
        );
      case "OFFPREFERENCE":
        return (
          <SoftRequestTemplate
            props={props}
            view="Manual Scheduling"
            env={window}
            cmo={CMO}
          />
        );
      case "SHIFT":
        return (
          <ShiftTemplate
            props={props}
            view="Manual Scheduling"
            env={window}
            cmo={CMO}
          />
        );
    }
  };

  const resourceHeaderTemplate = (props) => {
    if (
      props.resourceData.id === "OnCall" ||
      props.resourceData.id === "Requests"
    ) {
      return <div>{props.resourceData.name}</div>;
    } else {
      return (
        <>
          <div>{props.resourceData.office.name}</div>
          <div>
            {props.resourceData.start.substr(0, 5) +
              " - " +
              props.resourceData.end.substr(0, 5)}
          </div>
        </>
      );
    }
  };

  const monthTemplate = (props) => {
    switch (props.eventType) {
      case "SHIFT":
        return (
          <ShiftTemplate props={props} view="Month" env={window} cmo={CMO} />
        );
      case "SHIFTASSIGNMENT":
        return (
          <ShiftAssignmentTemplate
            props={props}
            view="Month"
            env={window}
            cmo={CMO}
          />
        );
      case "EMPLOYEEAVAILABILITY":
        return (
          <EmployeeAvailabilityTemplate
            props={props}
            view="Month"
            env={window}
            cmo={CMO}
          />
        );
      case "ONCALL":
        return (
          <OnCallTemplate props={props} view="Month" env={window} cmo={CMO} />
        );
      case "OFFPREFERENCE":
        return (
          <SoftRequestTemplate
            props={props}
            view="Month"
            env={window}
            cmo={CMO}
          />
        );
    }
  };

  const treeTemplate = (props) => {
    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitlist">
            <b>{props.name}</b>
            <div style={{ fontSize: 10, color: "#DODODO" }}>
              {props.skills &&
                props.skills.length > 0 &&
                props.skills.map((e) => e.name).join(", ")}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cellHeaderTemplate = (props) => {
    const today = isSameDay(new Date(), props.date);
    const formatted = format(props.date, "MM/dd/yyyy");
    let slackIssueDate = false;
    let issuesForDate;
    let under = [];
    if (ref.current.slackEvents) {
      slackIssueDate = Object.hasOwn(ref.current.slackEvents, formatted); // check if selected date has a slack issue
      if (slackIssueDate) {
        // get slack issues for the date selected
        issuesForDate = ref.current.slackEvents[formatted];

        // check if the staffing is low or high for the date selected
        var keys = Object.keys(issuesForDate);

        keys.forEach((key) => {
          var currentIssue = issuesForDate[key];
          if (
            parseInt(currentIssue.required) >
              parseInt(currentIssue.numAssigned) ||
            parseInt(currentIssue.slack) < 0
          ) {
            under.push(currentIssue);
          }
        });
      }
    }

    // check if the selected date has a draft schedule or released schedule
    const draft =
      (new Date(props.date) > new Date(draftStart) ||
        isSameDay(new Date(props.date), new Date(draftStart))) &&
      (new Date(props.date) < new Date(draftEnd) ||
        isSameDay(new Date(props.date), new Date(draftEnd)));

    const open =
      (new Date(props.date) > new Date(openStart) ||
        isSameDay(new Date(props.date), new Date(openStart))) &&
      (new Date(props.date) < new Date(openEnd) ||
        isSameDay(new Date(props.date), new Date(openEnd)));
    return (
      <Grid container data-testid="ShiftBasedEventLabel">
        <Grid item xs={12}>
          {slackIssueDate && under.length > 0 && (
            <Tooltip
              title={<ShiftSlack startDate={props.date} slacks={under} />}
              placement="left"
              arrow
              enterDelay={500}
              enterNextDelay={500}
              classes={{ tooltip: classes.tooltip }}
            >
              <span>
                {under && (
                  <WarningIcon
                    style={{
                      fontSize: 16,
                      marginBottom: -3,
                      color: "black",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={today ? classes.today : null}>
            {props.type === "monthCells"
              ? format(props.date, "dd")
              : format(props.date, "MMM dd, EEE")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {draft ? (
            <Typography variant="body2" style={{ color: "#8CADE1" }}>
              Draft
            </Typography>
          ) : open && currentView === "TimelineWeek" ? (
            <Typography variant="body2">
              <Person color="secondary" fontSize="small" /> open
            </Typography>
          ) : (
            <>
              <Typography
                variant="body2"
                style={{ color: "#8CADE1" }}
              ></Typography>
              <br />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const onPopupOpen = (args) => {
    var buttonElement =
      args.type === "QuickInfo"
        ? ".e-event-popup .e-delete"
        : ".e-schedule-dialog .e-event-delete";
    var deleteButton = document.querySelector(buttonElement);
    if (
      args.type === "Editor" &&
      args.data.participants &&
      args.data.participants.length === 0
    ) {
      deleteButton.ej2_instances[0].disabled = true;
    }
    if (args.type === "Editor" && currentView !== "TimelineWeek") {
      args.cancel = true;
    }
  };

  const closeQuickInfo = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.closeQuickInfoPopup();
  };

  // style quick info header
  const quickInfoHeader = (props) => {
    const personal =
      (props.participants &&
        props.participants.find(
          (participant) => participant.employee.id === user.id,
        )) ||
      (props.employee && props.employee.id === user.id);
    let enableManagerShiftSwitch =
      schedulePeriods.managerDraftRange &&
      isBefore(
        new Date(schedulePeriods.managerDraftRange.start),
        new Date(props.start),
      ) &&
      isAfter(
        new Date(schedulePeriods.managerDraftRange.end),
        new Date(props.end),
      );
    let enableEmployeeShiftSwitch =
      schedulePeriods.releasedRange &&
      isBefore(
        new Date(schedulePeriods.releasedRange.start),
        new Date(props.start),
      ) &&
      isAfter(new Date(schedulePeriods.releasedRange.end), new Date(props.end));

    //Check if the selected event is after the buffer day from today
    //get today and also the buffer days for office
    let bufferDate = new Date();
    let bufferDays = selectedOffice.shiftSwitchBufferDays;

    //add buffer day to today
    bufferDate.setDate(bufferDate.getDate() + bufferDays);

    //get selected events date
    let selectedDate = props.start;

    //check if selectedDate is after bufferDate
    const isAfterBufferDay = isAfter(selectedDate, bufferDate);

    return (
      <div>
        <Grid
          container
          justifyContent="flex-end"
          data-testid="quickInfoHeaderShift"
        >
          <Grid item style={{ marginTop: 8, marginRight: 8 }}>
            {managerAccess && props.eventType !== "ONCALL" && (
              <IconButton
                color="secondary"
                size="small"
                onClick={() => toggleEditRequest(props)}
                data-testid="editShiftButton"
              >
                <EditIcon style={{ color: "#000", fontSize: 18 }} />
              </IconButton>
            )}
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeQuickInfo}
            >
              <CloseIcon style={{ color: "#000", fontSize: 18 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {props.eventType === "EMPLOYEEAVAILABILITY" ? (
            <Grid item xs={12}>
              <Typography
                variant="h3"
                style={{ marginLeft: 8, marginBottom: 8, padding: 8 }}
              >
                {props.employee.firstName + " " + props.employee.lastName}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography variant="h3" style={{ marginLeft: 8 }}>
                {props.subject}
              </Typography>
            </Grid>
          )}

          {props.eventType === "EMPLOYEEAVAILABILITY" && (
            <Grid item container xs={6} justifyContent="flex-end">
              {allowCallIns && (
                <Grid item>
                  <Button
                    color="primary"
                    disabled={!personal}
                    onClick={toggleCallInForm}
                  >
                    Call In
                  </Button>
                </Grid>
              )}
            </Grid>
          )}

          {managerAccess && props.eventType === "SHIFTASSIGNMENT" ? (
            <Grid container justifyContent="center" alignItems="center">
              {isAfter(selectedDate, new Date()) && (
                <Grid item>
                  <Button
                    color="primary"
                    disabled={managerAccess ? false : !personal}
                    onClick={() => toggleShiftSwitchForm(props)}
                    style={{ marginLeft: 4, marginRight: 8 }}
                  >
                    Switch Shifts
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  disabled={managerAccess ? false : !personal}
                  onClick={() => {
                    toggleCallOffForm(props);
                    setQuickInfoProp(props);
                  }}
                  style={{ marginLeft: 4, marginRight: 8 }}
                >
                  Call Off
                </Button>
              </Grid>
            </Grid>
          ) : (
            personal && (
              <Grid container justifyContent="center" alignItems="center">
                {isAfterBufferDay && props.eventType === "SHIFTASSIGNMENT" && (
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={managerAccess ? false : !personal}
                      onClick={() => toggleShiftSwitchForm(props)}
                      style={{ marginLeft: 4, marginRight: 8 }}
                    >
                      Switch Shifts
                    </Button>
                  </Grid>
                )}
                {isAfter(selectedDate, new Date()) &&
                  props.eventType === "SHIFTASSIGNMENT" && (
                    <Grid item>
                      <Button
                        color="primary"
                        disabled={managerAccess ? false : !personal}
                        onClick={() => {
                          toggleCallOffForm(props);
                          setQuickInfoProp(props);
                        }}
                        style={{ marginLeft: 4, marginRight: 8 }}
                      >
                        Call Off
                      </Button>
                    </Grid>
                  )}
              </Grid>
            )
          )}
        </Grid>
      </div>
    );
  };

  // on event click bring up edit event popup
  const handleEventClick = (args) => {
    args.cancel = false;
  };

  const handleCellClick = (args) => {
    args.cancel = true;
  };
  if (ref.current === null) {
    return <CircularProgress />;
  } else {
    return (
      <div id="schedule" className="control-wrpper drag-sample-wrapper">
        <Grid container direction="row" spacing={2}>
          <Grid
            item
            xs={
              displayEmployeeGrid === true &&
              (managerAccess || user.manuallySchedulable === true)
                ? 10
                : 12
            }
          >
            <ScheduleComponent
              className="e-schedule"
              data-testid="scheduler"
              height={window.innerHeight - 175}
              ref={ref}
              selectedDate={currentDate}
              currentView={currentView}
              eventClick={handleEventClick}
              cellClick={handleCellClick}
              eventDoubleClick={handleEventClick}
              popupOpen={onPopupOpen}
              actionComplete={onActionComplete}
              created={onCreated}
              popupClose={manualSchedulingSave}
              eventRendered={onEventRendered}
              allowDragAndDrop={currentView === "TimelineWeek" ? true : false}
              dateHeaderTemplate={cellHeaderTemplate}
              quickInfoTemplates={{
                header: quickInfoHeader,
              }}
              cssClass="shiftCalendar excel-export schedule-cell-dimension"
              rowAutoHeight={true}
              eventSettings={{
                dataSource: [],
                fields: {
                  id: "id",
                  location: { name: "officeName", title: "Office" },
                  subject: { name: "subject", title: "Name" },
                  startTime: { name: "start" },
                  endTime: { name: "end" },
                  isAllDay: { name: "isAllDay" },
                  description: { name: "description" },
                },
              }}
            >
              <ViewsDirective>
                <ViewDirective
                  option="TimelineDay"
                  displayName="Shift Timeline"
                  timeScale={{ enable: true, interval: 360 }}
                  group={{ resources: ["Offices"] }}
                  startHour={
                    window.HIDE_EXTRA_TIME_ON_SHIFTTIMELINE ? "06:00" : "00:00"
                  }
                  endHour={
                    window.HIDE_EXTRA_TIME_ON_SHIFTTIMELINE ? "21:00" : "00:00"
                  }
                  interval={7}
                  eventTemplate={shiftTimelineTemplate}
                  dateHeaderTemplate={cellHeaderTemplate}
                />
                <ViewDirective
                  option="TimelineDay"
                  displayName="Employees Timeline"
                  group={{ resources: ["Employees"] }}
                  timeScale={{ enable: false }}
                  eventTemplate={employeeTimelineTemplate}
                  interval={7}
                  dateHeaderTemplate={cellHeaderTemplate}
                />
                <ViewDirective
                  option="TimelineWeek"
                  displayName="Manual Scheduling"
                  timeScale={{ enable: false }}
                  group={{
                    enableCompactView: false,
                    resources: ["Shifts"],
                  }}
                  eventTemplate={manualSchedulingTemplate}
                  interval={4}
                  resourceHeaderTemplate={resourceHeaderTemplate}
                  dateHeaderTemplate={cellHeaderTemplate}
                />
              </ViewsDirective>
              <ResourcesDirective>
                <ResourceDirective
                  field="shiftResource"
                  title="Shifts"
                  name="Shifts"
                  dataSource={allShiftResources}
                  textField="name"
                  idField="id"
                  allowMultiple={true}
                ></ResourceDirective>
                <ResourceDirective
                  field="officeResource"
                  title="officeResource"
                  name="Offices"
                  dataSource={officeResources}
                  textField="name"
                  idField="name"
                  allowMultiple={true}
                ></ResourceDirective>
                <ResourceDirective
                  field="employeeResource"
                  title="employeeResource"
                  name="Employees"
                  textField="name"
                  idField="id"
                  colorField="color"
                  allowMultiple={true}
                  dataSource={
                    getLocalStorageItem(`filter:${selectedOffice.name}:v0`) &&
                    getLocalStorageItem(`filter:${selectedOffice.name}:v0`)
                      .employeeFilters
                      ? getLocalStorageItem(
                          `filter:${selectedOffice.name}:v0`,
                        ).employeeFilters.filter(
                          (x) =>
                            x.checked === true && x.name !== "All Employees",
                        )
                      : employeeResources
                  }
                ></ResourceDirective>
              </ResourcesDirective>
              <Inject
                services={[
                  TimelineViews,
                  Month,
                  MonthAgenda,
                  ICalendarExport,
                  ExcelExport,
                  Print,
                  DragAndDrop,
                ]}
              />
            </ScheduleComponent>
          </Grid>
          {displayEmployeeGrid === true &&
            (managerAccess || user.manuallySchedulable === true) && (
              <EmployeeGrid
                onTreeDragStop={onTreeDragStop}
                onItemDrag={onItemDrag}
                employeeResources={employeeResources}
                skills={skills}
              />
            )}
        </Grid>
      </div>
    );
  }
});

export default Scheduler;
