import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_OFFICE_EMPLOYEES,
  GET_RELEVANT_SKILLS,
  AVAILABILITY_TYPES,
  GET_SHIFT_DEFINITIONS,
  CREATE_MANUAL_SHIFT_ASSIGNMENTS,
  FIND_OFFICE_CONSTRAINT,
} from "../../api/gqlQueries";
import Scheduler from "./Scheduler";
import { format } from "date-fns";
import { selectedDateVar, userVar, ManualEventsToSave } from "../../cache";
import {
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Roles from "../../Roles/roles";
import PrintIcon from "@mui/icons-material/Print";
import MangEditShift from "./MangEditShift";
import EditSoftTimeOff from "./EditSoftTimeOff";
import ShiftSwitchRequestForm from "../shiftSwitch/ShiftSwitchRequestForm";
import EmpCallInForm from "./EmpCallInForm";
import EditTimeOffRequestForm from "./EditTimeOffRequestForm";
import { toIsoDate } from "../../helpers/formatTime";
import Filter from "./Filter";
import AddCalendarEventForm from "./AddCalendarEventForm";
import AddSoftTimeOffRequest from "./AddSoftTimeOffRequest";
import Download from "./Download";
import { updateRecurrenceScheduleEvent } from "./UpdateEvents";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import CallOffShift from "./CallOffShift";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    marginTop: -7,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
  downloadPopover: {
    textAlign: "left",
  },
  filterInput: {
    width: "15%",
  },
}));

function Calendar({
  selectedOffice,
  allOffices,
  schedulePeriods,
  notifyDevelopers,
  environment,
  queryHandler,
}) {
  const user = userVar();
  const classes = useStyles();

  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.LEAD ||
    user.role === Roles.ADMIN
      ? true
      : false;
  const ref = useRef();
  const selectedDate = selectedDateVar();
  const [sortedAvailabilityTypes, setSortedAvailabilityTypes] = useState([]); //Passed into FilterMenu, TimeOffRequestForm, EditTimeOffRequestForm. Set from AVAILABILITY_TYPES query
  const [shiftSwitch, setShiftSwitch] = useState(null); //Toggle for ShiftSwitchRequestForm. toggleShiftSwitchForm to invert boolean.
  const [callIn, setCallIn] = useState(false); //Toggle for EmpCallInForm. toggleCallInForm to invert boolean

  const [shiftToEdit, setShiftToEdit] = useState();
  const [timeOffRequestToEdit, setTimeOffRequestToEdit] = useState();
  const [softRequestToEdit, setSoftRequestToEdit] = useState();
  const [callOffShift, setcallOffShift] = useState();
  const [quickInfoProp, setQuickInfoProp] = useState();

  const [openDownload, setopenDownload] = useState(null); //Onclick, sets openDownload to event, and onClose it is set to Null which is turned into Boolean for openD variable, used similar to toggle.
  const openD = Boolean(openDownload);
  const anchorId = openD ? "download-popover" : undefined;
  const maxGreyout = selectedOffice.maxGreyoutRequests;
  const schedulePeriodWeeks = selectedOffice.scheduleDuration
    ? selectedOffice
    : 4;
  const officeFloatStatus = selectedOffice.floatStatus;

  // Want a single state variable that both tracks the shift to edit and implies whether form is open or closed
  // Need a callback to pass to components to update the shift to edit

  // handleEditClick -> Scheduler (sets the shift to edit and toggles edit shift)
  // toggleEditShift -> MangEditShift (inverts boolean to open/close form)
  //                 -> handleEditClick
  // setEditShift -> toggleEditShift -> handleEditClick (boolean whether form is open or closed)
  // setShiftToEdit -> handleEditClick (tracks the shift to edit)
  //                -> MangEditShift

  /*
  State variables:
- track shift to edit
- track pto to edit
- track soft time off to edit
- track whether to show the pto to create form
- track whether to show the soft request to create form
  */

  const toggleShiftSwitchForm = (event) => {
    setShiftSwitch(event);
  };

  const closeShiftSwitchForm = () => {
    setShiftSwitch(null);
  };

  const toggleCallOffForm = (event) => {
    setcallOffShift(event);
  };

  const closeCallOffForm = () => {
    setcallOffShift(null);
  };

  const toggleCallInForm = () => setCallIn(!callIn);

  const closeShiftToEditForm = () => {
    setShiftToEdit(null);
    ref.current.refreshLayout();
  };

  const closeEditTimeOffForm = () => setTimeOffRequestToEdit(null);

  const closeEditSoftRequestForm = () => setSoftRequestToEdit(null);

  const toggleEditRequest = (event) => {
    if (event.eventType === "SHIFTASSIGNMENT" || event.eventType === "SHIFT") {
      setShiftToEdit(event);
    } else if (event.eventType === "OFFPREFERENCE") {
      setSoftRequestToEdit(event);
    } else {
      setTimeOffRequestToEdit(event);
    }
    ref.current.refreshLayout();
  };

  const handleEditClick = (shift) => {
    setShiftToEdit(shift);
  };

  const PrintIconClick = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.print();
  };

  const managerDraftRanges = schedulePeriods.managerDraftRange;

  let draftStart;
  let draftEnd;
  let openStart;
  let openEnd;

  draftStart = managerDraftRanges?.start;
  draftEnd = managerDraftRanges?.end;

  if (managerAccess) {
    openStart = schedulePeriods.managerOpenRange?.start;
    openEnd = schedulePeriods.managerOpenRange?.end;
  } else {
    openStart = schedulePeriods.employeeOpenRange?.start;
    openEnd = schedulePeriods.employeeOpenRange?.end;
  }

  const scheduleEndDate = schedulePeriods.nextPeriodToCreateStart
    ? schedulePeriods.nextPeriodToCreateStart
    : format(new Date(), "MMM-dd-yyyy");

  //All skills of this particular office
  const { data: allSkillData, loading: allSkillDataLoading } = useQuery(
    GET_RELEVANT_SKILLS,
    {
      variables: {
        office: parseInt(selectedOffice.id),
      },
      onError(error) {
        console.log(error);
        toastShow(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again." +
            error.message,
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on FIND_SKILL_TYPE Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [createManualShiftAssignments] = useMutation(
    CREATE_MANUAL_SHIFT_ASSIGNMENTS,
    {
      onCompleted(d) {
        ManualEventsToSave([]);
        try {
          updateRecurrenceScheduleEvent(
            ref.current,
            d.createManualShiftAssignments.assignments,
            selectedOffice.name,
          );
        } catch (err) {
          toastShow(
            "Manual Shift Assignments could not be created. Please try again. ",
            "Error",
          );
          ref.current.hideSpinner();
        }
        toastShow("Successfully created manual shift assignments", "Success");
      },
      onError(e) {
        ref.current.hideSpinner();
        console.log(e);
        toastShow(
          "Error creating manual shift assignments." + e.message,
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on CREATE_MANUAL_SHIFT_ASSIGNMENTS mutation. Environment: " +
              environment +
              ". Graphql " +
              e,
          },
        });
      },
    },
  );

  //different types of Time Off's for this department

  useQuery(AVAILABILITY_TYPES, {
    onCompleted(data) {
      setSortedAvailabilityTypes(data.availabilityTypes);
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again." +
          error.message,
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  //resource data for employee timeline
  const formatEmployeeData = (data) => {
    const resources = data
      .map((employee) => {
        return {
          id: employee.id,
          name: `${employee.lastName}, ${employee.firstName}`,
          skills: employee.skills,
        };
      })
      .filter(Boolean);
    return resources;
  };

  //get all employees for selected office
  const selectedOfficeEmployees = useQuery(GET_OFFICE_EMPLOYEES, {
    variables: {
      office: parseInt(selectedOffice.id),
      start: toIsoDate(new Date()),
    },
    onCompleted(d) {},
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again." +
          error.message,
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE_EMPLOYEES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const officeConstraints = useQuery(FIND_OFFICE_CONSTRAINT, {
    variables: {
      officeId: parseInt(selectedOffice.id),
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again." +
          error.message,
        "Error",
      );
    },
  });

  const shiftDefinitions = useQuery(GET_SHIFT_DEFINITIONS, {
    variables: {
      officeId: parseInt(selectedOffice.id),
      includeChildren: true,
    },
    onError(err) {
      toastShow(
        "Something went wrong and we are looking into it. " + err.message,
        "Error",
      );
    },
  });

  let allOfficeFormatted = [
    ...allOffices
      .filter(
        (e) => e.id === selectedOffice.id || e.parent?.id === selectedOffice.id,
      )
      .map((e) => {
        return { id: e.id, name: e.name };
      })
      .sort((a, b) => {
        if (a.id === selectedOffice.id) {
          return -1;
        }
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    {
      id: "OnCall",
      name: "On Call",
    },
    {
      id: "Requests",
      name: "Requests",
    },
  ];

  return (
    <div
      data-testid="calendarId"
      style={{
        height: parseInt(window.innerHeight) - 175,
      }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="right"
        className={classes.headerSpacing}
      >
        <Grid className={classes.filterInput} item></Grid>
        {(window.HIDE_EMPLOYEE_REQUEST_FORMS === false ||
          window.HIDE_EMPLOYEE_REQUEST_FORMS === "false" ||
          ((window.HIDE_EMPLOYEE_REQUEST_FORMS === true ||
            window.HIDE_EMPLOYEE_REQUEST_FORMS === "true") &&
            user.isAdmin)) && (
          <AddCalendarEventForm
            scheduleEndDate={scheduleEndDate}
            ref={ref}
            employees={
              selectedOfficeEmployees.data
                ? formatEmployeeData(
                    selectedOfficeEmployees.data.officeEmployees,
                  )
                : []
            }
            availabilityTypes={sortedAvailabilityTypes}
            notifyDevelopers={notifyDevelopers}
            selectedOffice={selectedOffice}
          />
        )}

        {user.isSchedulable === true &&
          (window.HIDE_EMPLOYEE_REQUEST_FORMS === false ||
            window.HIDE_EMPLOYEE_REQUEST_FORMS === "false") && (
            <Grid item>
              <AddSoftTimeOffRequest
                scheduleEndDate={scheduleEndDate}
                maxGreyout={maxGreyout}
                schedulePeriodWeeks={schedulePeriodWeeks}
                notifyDevelopers={notifyDevelopers}
                ref={ref}
                selectedOffice={selectedOffice}
              />
            </Grid>
          )}
        <Download />
        <Grid item>
          <Button
            id="print"
            color="secondary"
            onClick={PrintIconClick}
            data-testid="printIcon"
          >
            <PrintIcon style={{ marginRight: 5 }} /> Print
          </Button>
        </Grid>
        <Grid item>
          {allSkillDataLoading ||
          sortedAvailabilityTypes.length === 0 ||
          !selectedOfficeEmployees.data ||
          !sortedAvailabilityTypes ? (
            <CircularProgress />
          ) : (
            <Filter
              jobTypes={allSkillData.requiredSkills}
              shiftNames={[]}
              availabilityTypes={sortedAvailabilityTypes}
              officeFloatStatus={officeFloatStatus}
              allOffices={allOffices}
              employeeResources={
                selectedOfficeEmployees.data
                  ? formatEmployeeData(
                      selectedOfficeEmployees.data.officeEmployees,
                    )
                  : []
              }
              selectedOffice={selectedOffice}
              ref={ref}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          <Scheduler
            officeResources={allOfficeFormatted}
            employeeResources={
              selectedOfficeEmployees.data
                ? formatEmployeeData(
                    selectedOfficeEmployees.data.officeEmployees,
                  )
                : []
            }
            shiftResources={
              shiftDefinitions.loading
                ? []
                : shiftDefinitions.data &&
                  shiftDefinitions.data.shiftDefinitions
            }
            officeConstraints={
              officeConstraints.loading
                ? []
                : officeConstraints.data && officeConstraints.data.constraints
            }
            selectedOffice={selectedOffice}
            queryHandler={queryHandler}
            ref={ref}
            schedulePeriods={schedulePeriods}
            toggleShiftSwitchForm={toggleShiftSwitchForm}
            toggleCallOffForm={toggleCallOffForm}
            handleEditClick={handleEditClick}
            draftStart={draftStart}
            draftEnd={draftEnd}
            openStart={openStart}
            openEnd={openEnd}
            managerAccess={managerAccess}
            skills={allSkillDataLoading ? [] : allSkillData.requiredSkills}
            toggleEditRequest={toggleEditRequest}
            toggleCallInForm={toggleCallInForm}
            CMO={allOfficeFormatted.length > 3}
            createManualShiftAssignments={createManualShiftAssignments}
            setQuickInfoProp={setQuickInfoProp}
          />
        </Grid>
        <Dialog open={shiftSwitch != null} fullWidth maxWidth="sm">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
              position: "relative",
            }}
            data-testid="ShiftBasedShiftSwitchForm"
          >
            {shiftSwitch && (
              <ShiftSwitchRequestForm
                closeDialog={closeShiftSwitchForm}
                selectedShift={shiftSwitch}
                scheduleEndDate={scheduleEndDate}
                selectedDate={selectedDate}
                selectedOffice={selectedOffice}
                ref={ref}
                notifyDevelopers={notifyDevelopers}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={callIn} fullWidth maxWidth="xs">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              height: 400,
              position: "relative",
            }}
            data-testid="ShiftBasedCallInForm"
          >
            <EmpCallInForm
              closeDialog={toggleCallInForm}
              notifyDevelopers={notifyDevelopers}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={shiftToEdit != null} fullWidth maxWidth="md">
          <DialogContent data-testid="ShiftBasedEditShiftForm">
            <Grid
              container
              component={DialogContent}
              direction="column"
              wrap="nowrap"
              justifyContent="space-between"
              style={{
                padding: 30,
                overflowX: "hidden",
                overflowY: "auto",
                height: 675,
              }}
            >
              {shiftToEdit && (
                <MangEditShift
                  shiftEvent={shiftToEdit}
                  closeDialog={closeShiftToEditForm}
                  notifyDevelopers={notifyDevelopers}
                  environment={environment}
                  selectedDate={selectedDate}
                  setShiftToEdit={setShiftToEdit}
                  ref={ref}
                  events={[]}
                  selectedOffice={selectedOffice}
                />
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={timeOffRequestToEdit != null} fullWidth maxWidth="md">
          <DialogContent
            style={{ padding: 30, height: "auto" }}
            data-testid="ShiftBasedEditTimeOffForm"
          >
            {timeOffRequestToEdit && (
              <EditTimeOffRequestForm
                closeDialog={closeEditTimeOffForm}
                timeOffRequestToEdit={timeOffRequestToEdit}
                availabilityTypes={sortedAvailabilityTypes}
                notifyDevelopers={notifyDevelopers}
                ref={ref}
                selectedOffice={selectedOffice}
              />
            )}
          </DialogContent>
        </Dialog>

        <Dialog open={softRequestToEdit != null} fullWidth maxWidth="xs">
          <DialogContent
            style={{ padding: 30 }}
            data-testid="ShiftBasedEditSoftRequestForm"
          >
            {softRequestToEdit && (
              <EditSoftTimeOff
                closeDialog={closeEditSoftRequestForm}
                scheduleEndDate={scheduleEndDate}
                maxGreyout={maxGreyout}
                schedulePeriodWeeks={schedulePeriodWeeks}
                softRequestToEdit={softRequestToEdit}
                notifyDevelopers={notifyDevelopers}
                ref={ref}
                selectedOffice={selectedOffice}
              />
            )}
          </DialogContent>
        </Dialog>

        <Dialog open={callOffShift != null} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: "auto" }}
            data-testid="ShiftBasedEditTimeOffForm"
          >
            <CallOffShift
              closeDialog={closeCallOffForm}
              managerAccess={managerAccess}
              quickInfoProp={quickInfoProp}
              ref={ref}
              selectedOffice={selectedOffice}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </div>
  );
}

export default Calendar;
