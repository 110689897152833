import React from "react";
import ReactDOM from "react-dom";
import App from "./features/general/App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
// import { appsettingsVar } from './cache';
import { createUploadLink } from "apollo-upload-client";
import { registerLicense } from "@syncfusion/ej2-base";
import { RetryLink } from "@apollo/client/link/retry";
import { from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { fetchAuthSession } from "aws-amplify/auth";
import { handleLogout } from "./features/general/LogOutDialog";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./features/errors/ErrorPage";

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdEdjUH5Zc3VVRGFa",
);
export const localDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const environment = process.env.NODE_ENV;
const baseURL = window.location.origin;
let backend = "";

//get backend url based on development environment
if (environment === "development") {
  backend = "https://dev.balancescheduler.com/api/graphql";
} else if (baseURL.includes("amplify")) {
  backend = "/api/graphql/";
} else {
  backend = "/api/graphql/";
}

async function refreshToken() {
  try {
    const { tokens } = await fetchAuthSession({ forceRefresh: true });
    if (tokens && tokens.idToken) {
      sessionStorage.setItem("jwt", tokens.idToken.toString());
    } else {
      handleLogout();
    }
  } catch (err) {
    console.log(err);
    handleLogout();
  }
}

const uploadLink = createUploadLink({
  // uri: 'https://backenddemo.balancescheduler.com/graphql'
  uri: backend,
  // uri: 'https://mission-demo-backend.balancescheduler.com/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authent3ication token from local storage if it exists
  const token = sessionStorage.getItem("jwt");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

/*
Retry Link handles retries for only network errors.
https://www.apollographql.com/docs/react/api/link/apollo-link-retry
*/
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    refreshToken();
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  // uri: 'http://localhost:8000/graphql',
  link: from([
    retryLink,
    errorLink,
    authLink.concat(uploadLink),
    //https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link
  ]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ErrorBoundary fallbackRender={ErrorPage}>
      <App />
    </ErrorBoundary>
  </ApolloProvider>,
  document.getElementById("root"),
);
