import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import roles from "../../Roles/roles";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  EMPLOYEE_ACCEPT_SHIFT_SWITCH,
  //MANAGER_APPROVE_SHIFT_SWITCH,
  // EMPLOYEE_DENY_SHIFT_SWITCH,
  //MANAGER_DENY_SHIFT_SWITCH,
  UPDATE_NOTIFICATION,
  MANAGER_APPROVE_EMPLOYEE_AVAILABILITY,
  MANAGER_DENY_EMPLOYEE_AVAILABILITY,
  GET_RESCHEDULE_OPTION,
  APPROVE_OPTION,
  DENY_OPTION,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import AddEmployeesForm from "../rescheduling/AddEmployeesForm";
import { format } from "date-fns";
import parse from "html-react-parser";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { setSessionStorageItem } from "../../hooks/storage/useStorage";

let toastObj;
function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  topSpacing: {
    marginTop: 20,
  },
  button: {
    width: 90,
  },
  input: {
    width: 384,
  },
  unread: {
    background: "rgba(134, 134, 137, 0.15)",
  },
  unreadText: {
    fontWeight: "bold",
  },
}));

const NotificationItem = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { notification, handleCheckboxToggle, checked, refetch } = props;

  const user = userVar();

  useEffect(() => {
    if (notification.url && notification.url.includes("manager_approvals")) {
      const optionId = notification.url.split("=")[1];
      console.log(optionId);
      getOption({ variables: { id: parseInt(optionId) } });
    }
  }, []);

  const [showDenialReason, setShowDenialReason] = useState(false);
  const [denialReason, setDenialReason] = useState("");
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [rescheduleOption, setRescheduleOption] = useState();

  const [getOption] = useLazyQuery(GET_RESCHEDULE_OPTION, {
    onCompleted(data) {
      console.log(data);
      setRescheduleOption(data.rescheduleOptions[0]);
    },
    onError(error) {
      console.log(error);
    },
  });

  const [approveOption] = useMutation(APPROVE_OPTION, {
    onCompleted(data) {
      refetch();
      console.log(data);
      toastShow("Manager Approve Option", "Success");
    },
    onError(error) {
      console.log(error);
      toastShow("Error Approve Option", "Error");
    },
  });

  const [denyOption] = useMutation(DENY_OPTION, {
    onCompleted(data) {
      console.log(data);
      refetch();
      toastShow("Manager Deny Option", "Success");
    },
    onError(error) {
      console.log(error);
      toastShow("Error Deny Option", "Error");
    },
  });

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    onCompleted(data) {
      refetch();
    },
    onError(error) {
      console.log(error);
      toastShow("Error Update Notification", "Error");
    },
  });

  const [employeeAcceptShiftSwitch] = useMutation(
    EMPLOYEE_ACCEPT_SHIFT_SWITCH,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        toastShow("Employee Accept Shift Switch", "Success");
      },
      onError(error) {
        console.log(error);
        toastShow("Error Accept Shift Switch", "Error");
      },
    },
  );

  const [approveTORequest] = useMutation(
    MANAGER_APPROVE_EMPLOYEE_AVAILABILITY,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        toastShow("Manager Approve Time Off Request", "Success");
      },
      onError(error) {
        console.log(error);
        toastShow("Error Approve Time Off", "Error");
      },
    },
  );

  const [denyTORequest] = useMutation(MANAGER_DENY_EMPLOYEE_AVAILABILITY, {
    update(cache, { data: { denyTimeOffRequestWithNotifications } }) {
      cache.evict({
        id: `EmployeeAvailabilityNode:${denyTimeOffRequestWithNotifications.timeOffRequest.id}`,
      });
    },
    onCompleted(data) {
      console.log(data);
      refetch();
      setShowDenialReason(false);
      setDenialReason("");
      toastShow("Manager Deny Time Off Request", "Success");
    },
    onError(error) {
      console.log(error);
      toastShow("Error Deny Time Off", "Error");
    },
  });

  const handleApproveOption = (e) => {
    e.stopPropagation();
    approveOption({
      variables: {
        option: parseInt(rescheduleOption.id),
        approver: parseInt(user.id),
      },
    });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleDenyOption = (e) => {
    e.stopPropagation();
    denyOption({
      variables: {
        option: parseInt(rescheduleOption.id),
        denier: parseInt(user.id),
      },
    });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleManagerApproveSSRequest = (event, notification) => {
    event.stopPropagation();
    //mutation to add manager approver to shift switch
    //backend will update shifts to reflect switch
    // managerApproveShiftSwitch({
    //   variables: {
    //     id: parseInt(notification.shiftSwitch.id),
    //     approver: user.id,
    //   },
    // });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
    history.push("/Requests");
  };

  const handleEmployeeAcceptSSRequest = (event, notification) => {
    event.stopPropagation();
    let candidateNumber;

    //loop through candidates to find one that matches logged in user
    for (let i = 0; i < 4; i++) {
      if (
        notification.shiftSwitch[`candidate${i + 1}`].employee.id === user.id
      ) {
        candidateNumber = i + 1;
        break;
      }
    }

    //mutation to move user to accepted employee field on shift switch
    //backend will create notification for manager approval
    employeeAcceptShiftSwitch({
      variables: {
        id: parseInt(notification.shiftSwitch.id),
        candidateNumber: candidateNumber,
      },
    });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleManagerDenyRequest = (notification) => {
    if (notification.type === "SSM") {
      // managerDenyShiftSwitch({
      //   variables: {
      //     id: notification.shiftSwitch.id,
      //     deniedText: denialReason,
      //     denier: parseInt(user.id),
      //   },
      // });
    } else if (notification.type === "TOR") {
      denyTORequest({
        variables: {
          id: notification.availability.id,
          denyingManager: parseInt(user.id),
          deniedText: denialReason,
        },
      });
    }
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleEmployeeDenyRequest = (event, notification) => {
    event.stopPropagation();

    let candidateNumber;
    for (let i = 0; i < 4; i++) {
      if (
        notification.shiftSwitch[`candidate${i + 1}`].employee.id === user.id
      ) {
        candidateNumber = i + 1;
        break;
      }
    }
    // employeeDenyShiftSwitch({
    //   variables: {
    //     id: notification.shiftSwitch.id,
    //     candidateNumber: candidateNumber,
    //     // input: {
    //     //     [`candidate${candidateNumber}Denied`]: true
    //     // }
    //   },
    // });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: {
          read: true,
          depreciatedText: "You denied this shift switch request.",
        },
      },
    });
  };

  const showManagerDenialForm = (event) => {
    event.stopPropagation();
    setShowDenialReason(true);
  };

  const handleManagerApproveTORequest = (event, notification) => {
    event.stopPropagation();
    // approveTORequest({
    //   variables: {
    //     id: parseInt(notification.availability.id),
    //     approvingManager: parseInt(user.id),
    //   },
    // });

    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: {
          read: true,
        },
      },
    });
    history.push("/Requests");
  };

  const deprecatedTitle = (title) => {
    return (
      <Typography>
        <i>No Longer Active - {title}</i>
      </Typography>
    );
  };

  const getDateFromDescription = (description) => {
    const potentialDates = description.match(/\d{4}-\d{2}-\d{2}/);
    console.log("potential date", potentialDates[0]);
    const date = new Date(potentialDates[0]);
    const timestamp = Date.parse(date);
    if (isNaN(timestamp) === false) {
      return date;
    }
  };

  const closeAddOptions = () => {
    setShowAddOptions(false);
  };

  const getDescription = (option) => {
    const individual = option.rescheduleindividualSet[0];
    const employee = individual.employee;
    const employeeName = employee.firstName + " " + employee.lastName;
    const shift = individual.rescheduleactionSet[0].shift;
    const formattedStart = format(new Date(shift.start), "dd MMM yyyy HH:mm");
    const formattedEnd = format(new Date(shift.end), "HH:mm");
    return `${employeeName} would be added to ${formattedStart}-${formattedEnd}`;
  };

  const parseHTMLDescription = (description) => {
    let desc = notification.html === true ? parse(description) : description;
    return desc;
  };

  const handleViewInCalendar = (notification) => {
    setSessionStorageItem(
      "currentDate",
      new Date(getDateFromDescription(notification.description)),
    );
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  return (
    <>
      <Grid
        container
        component={ListItem}
        divider
        className={notification.read ? null : classes.unread}
      >
        <Grid
          container
          item
          justifyContent="space-between"
          wrap="nowrap"
          alignContent="center"
          xs={12}
          onClick={handleCheckboxToggle(notification.id)}
        >
          <Grid item container wrap="nowrap">
            <Grid item>
              <ListItemIcon>
                <Checkbox checked={checked.indexOf(notification.id) !== -1} />
              </ListItemIcon>
            </Grid>
            <Grid item data-testid="notificationsDescription">
              {notification.depreciatedText ? (
                <ListItemText
                  primary={
                    <Typography
                      className={notification.read ? null : classes.unreadText}
                    >
                      {deprecatedTitle(notification.title)}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={notification.read ? null : classes.unreadText}
                    >
                      {parseHTMLDescription(notification.description)}
                    </Typography>
                  }
                />
              ) : rescheduleOption ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className={notification.read ? null : classes.unreadText}
                    >
                      {notification.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={notification.read ? null : classes.unreadText}
                    >
                      {getDescription(rescheduleOption)}
                    </Typography>
                  }
                />
              ) : (
                <ListItemText
                  // primary={notification.title}
                  primary={
                    <Typography
                      className={notification.read ? null : classes.unreadText}
                    >
                      {notification.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      className={notification.read ? null : classes.unreadText}
                    >
                      {parseHTMLDescription(notification.description)}
                    </Typography>
                  }
                />
              )}
            </Grid>
          </Grid>
          {notification.url &&
            notification.url.includes("manager_approvals") &&
            user.role === roles.MANAGER &&
            rescheduleOption &&
            rescheduleOption.status === "ACCEPTED" && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDenyOption}
                    className={classes.button}
                  >
                    Deny
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApproveOption}
                    className={classes.button}
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.url &&
            notification.url.includes("FloatNeeds") &&
            user.role === roles.MANAGER && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to={notification.url}
                    disabled={showDenialReason}
                  >
                    Handle Request
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "SSM" &&
            notification.shiftSwitch.acceptor !== null &&
            user.role === roles.MANAGER &&
            notification.depreciatedText === null && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                {/* <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to="/Landing/shift-switch"
                    disabled={showDenialReason}
                  >
                    Shift Switch Requests
                  </Button>
                </Grid> */}
                {/* <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(event) => showManagerDenialForm(event)}
                    className={classes.button}
                    disabled={showDenialReason}
                  >
                    Deny
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleManagerApproveSSRequest(event, notification)
                    }
                    className={classes.button}
                    disabled={showDenialReason}
                  >
                    View in Requests
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "SSR" &&
            notification.shiftSwitch.acceptor === null &&
            notification.depreciatedText === null && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                {/* <Grid item>
                                <Button
                                    color='primary'
                                    component={Link}
                                    to='/Landing'
                                >
                                    Details
                                </Button>
                            </Grid> */}
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(event) =>
                      handleEmployeeDenyRequest(event, notification)
                    }
                    className={classes.button}
                  >
                    Deny
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleEmployeeAcceptSSRequest(event, notification)
                    }
                    className={classes.button}
                  >
                    Accept
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "TOR" &&
            user.role === roles.MANAGER &&
            notification.depreciatedText === null && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to="/Schedule"
                    onClick={() => {
                      handleViewInCalendar(notification);
                    }}
                  >
                    View in Calendar
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(event) => showManagerDenialForm(event)}
                    className={classes.button}
                  >
                    Deny
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleManagerApproveTORequest(event, notification)
                    }
                    className={classes.button}
                  >
                    View in Requests
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "ECI" && user.role === roles.MANAGER && (
            <Grid item container xs={5} spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    setShowAddOptions(true);
                    event.stopPropagation();
                  }}
                  // className={classes.addOptionsButton}
                >
                  Show Options
                </Button>
              </Grid>
            </Grid>
          )}

          {/* look at next two .includes methods once I see all the descriptions */}

          {notification.description.includes("schedule") &&
            notification.description.includes("create") &&
            notification.read !== true && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" component={Link} to="/CreateSchedule">
                    Create Schedule
                  </Button>
                </Grid>
              </Grid>
            )}
          {(notification.type === "SCG" ||
            notification.type === "SCR" ||
            notification.type === "SCP") &&
            notification.read !== true && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to="/Schedule"
                    onClick={() => {
                      handleViewInCalendar(notification);
                    }}
                  >
                    View in Calendar
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.description.includes("preferences") &&
            notification.read !== true && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" component={Link} to="/Preferences">
                    Update Preferences
                  </Button>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Dialog open={showDenialReason} fullWidth maxWidth="xs">
        <DialogContent style={{ padding: 30 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h3">Reason for Denial</Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                value={denialReason}
                onChange={(e) => setDenialReason(e.target.value)}
                className={classes.input}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleManagerDenyRequest(notification);
                }}
              />
            </Grid>
            <Grid item container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleManagerDenyRequest(notification)}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setShowDenialReason(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={showAddOptions} fullWidth maxWidth="sm">
        <DialogContent style={{ padding: 30 }}>
          <AddEmployeesForm
            selectedNotification={notification}
            closeDialog={closeAddOptions}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotificationItem;
