import React, { useState } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  GET_ONLY_USER_SHIFTS,
  GET_EMPLOYEE_AVAILABILITY,
  GET_PREFERENCES,
  TOTAL_HOURS_THIS_SCHEDULE_FOR_EMPLOYEE,
  GET_OFFICE_PERIOD_STATUS,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import { format, add } from "date-fns";
import { useQuery, useLazyQuery } from "@apollo/client";

const ScheduleInfo = (props) => {
  const history = useHistory();
  const user = userVar();
  const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";
  const selectedOffice = props.selectedOffice;
  const twoweeksfromtoday =
    format(add(new Date(), { weeks: 1 }), "yyyy-MM-dd") + "T00:00:00";
  const userShifts = useQuery(GET_ONLY_USER_SHIFTS, {
    variables: {
      employeeId: parseInt(user.id),
      rangeStart: today,
      rangeEnd: twoweeksfromtoday,
    },
  });

  const [currentSchedulePeriodData, SetCurrentSchedulePeriodData] = useState();

  const employeeAvailability = useQuery(GET_EMPLOYEE_AVAILABILITY, {
    variables: {
      employeeId: parseInt(user.id),
    },
  });

  const employeePreferences = useQuery(GET_PREFERENCES, {
    variables: {
      id: parseInt(user.id),
      office: parseInt(selectedOffice.id),
    },
  });

  // const totalHoursThisSchedule = useQuery(
  //   TOTAL_HOURS_THIS_SCHEDULE_FOR_EMPLOYEE,
  //   {
  //     variables: {
  //       period: parseInt(
  //         props.schedulePeriods.data.getOfficePeriodStatus
  //           .currentSchedulePeriodId,
  //       ),
  //       employee: parseInt(user.id),
  //     },
  //     onCompleted(d) {
  //       SetCurrentSchedulePeriodData(d.shiftAnalytics);
  //     },
  //   },
  // );

  if (
    userShifts.loading ||
    employeeAvailability.loading ||
    employeePreferences.loading ||
    props.schedulePeriods.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const userShiftPrefs =
      employeePreferences.data.preferences &&
      employeePreferences.data.preferences.length > 0 &&
      employeePreferences.data.preferences.filter(
        (e) =>
          e.type.type === "DAYS" ||
          e.type.type === "NIGHTS" ||
          e.type.type === "SWINGS" ||
          e.type.type === "24HR",
      );
    const preferedShift =
      userShiftPrefs &&
      userShiftPrefs.reduce((a, b) => (a = a.value > b.value ? a : b), 0);

    const availabilityAfterToday =
      employeeAvailability.data.availability &&
      employeeAvailability.data.availability.length > 0 &&
      employeeAvailability.data.availability.filter(
        (e) => new Date(e.firstday) >= new Date(),
      );

    availabilityAfterToday &&
      availabilityAfterToday.length > 0 &&
      availabilityAfterToday.sort(
        (a, b) => new Date(b.firstday) > new Date(a.firstday),
      );

    return (
      <div>
        <div>
          <Box m={2}>
            <Typography variant="h3">SCHEDULE INFO {}</Typography>
          </Box>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => {
            (window.SHOW_CALENDAR_ON_LOAD === true ||
              window.SHOW_CALENDAR_ON_LOAD === "true") &&
            userVar().isEmployee === true
              ? history.push("/")
              : history.push("/Schedule");
          }}
        >
          {" "}
          <Typography variant="body1">
            Shifts This Week: {userShifts.data.userShifts.length}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => {
            (window.SHOW_CALENDAR_ON_LOAD === true ||
              window.SHOW_CALENDAR_ON_LOAD === "true") &&
            userVar().isEmployee === true
              ? history.push("/")
              : history.push("/Schedule");
          }}
        >
          {" "}
          <Typography variant="body1">
            Next Break:{" "}
            {availabilityAfterToday[0]
              ? format(
                  new Date(availabilityAfterToday[0].firstday),
                  "MMM-dd-yyyy",
                ) +
                "(" +
                availabilityAfterToday[0]?.type.name +
                ")"
              : "None Scheduled"}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() =>
            user.isSchedulable
              ? history.push("/Preferences")
              : (window.SHOW_CALENDAR_ON_LOAD === true ||
                    window.SHOW_CALENDAR_ON_LOAD === "true") &&
                  userVar().isEmployee === true
                ? history.push("/")
                : history.push("/Schedule")
          }
        >
          {" "}
          <Typography variant="body1">
            Preferred Shift:{" "}
            {user.isSchedulable
              ? preferedShift.type
                ? preferedShift.type.type
                : "N/A"
              : "N/A"}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => {
            (window.SHOW_CALENDAR_ON_LOAD === true ||
              window.SHOW_CALENDAR_ON_LOAD === "true") &&
            userVar().isEmployee === true
              ? history.push("/")
              : history.push("/Schedule");
          }}
        >
          {" "}
          <Typography variant="body1">Scheduled Through: </Typography>
          <Typography variant="body1">
            {props.schedulePeriods &&
            props.schedulePeriods.data.getOfficePeriodStatus.publishedThru
              ? format(
                  new Date(
                    props.schedulePeriods.data.getOfficePeriodStatus.publishedThru,
                  ),
                  "MMM-dd-yyyy",
                )
              : format(new Date(), "MMM-dd-yyyy")}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => {
            (window.SHOW_CALENDAR_ON_LOAD === true ||
              window.SHOW_CALENDAR_ON_LOAD === "true") &&
            userVar().isEmployee === true
              ? history.push("/")
              : history.push("/Schedule");
          }}
        >
          {" "}
          <Typography variant="body1">
            Hours This Schedule:{" "}
            {user.isSchedulable && currentSchedulePeriodData
              ? currentSchedulePeriodData.length > 0 &&
                currentSchedulePeriodData[0].officeHours
                  .map((e) => e.totalHours)
                  .reduce((a, b) => a + b, 0)
              : "N/A"}{" "}
          </Typography>
        </div>
      </div>
    );
  }
};

export default ScheduleInfo;
