import React, { forwardRef } from "react";
import { Grid, Button, Typography, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import {
  EMPLOYEE_CALL_IN,
  SAVE_SHIFT_CHANGES,
  SLACK_FRAGMENT,
} from "../../api/gqlQueries";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { updateScheduleEvent } from "./UpdateEvents";
import { format } from "date-fns";
import { transformDate, formatTime } from "../../helpers/formatTime";

const CallOffShift = forwardRef((props, ref) => {
  const { closeDialog, managerAccess, quickInfoProp, selectedOffice } = props;
  const useStyles = makeStyles((theme) => ({
    container: {
      position: "relative",
      padding: theme.spacing(2),
    },
    button: {
      width: 90,
    },
    header: {
      marginBottom: 20,
      fontWeight: "bold",
    },
    text: {
      marginTop: 20,
      marginBottom: 10,
    },
    closeButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const isManager = managerAccess;

  const [saveShiftChanges] = useMutation(SAVE_SHIFT_CHANGES, {
    update(cache, { data: { saveShiftChanges } }) {
      const paginator = new DateTimePaginator(1);
      saveShiftChanges.slacks.forEach((element) => {
        const start = new Date(element.interval.start);
        const formatted = format(start, "MM/dd/yyyy");
        const dates = paginator.getQueryDateRanges(start, start);
        const variables = {
          issuesOnly: true,
          office: selectedOffice.id,
          pageEnd: dates[0][1].toISOString(),
          pageStart: dates[0][0].toISOString(),
        };
        const strVariables = JSON.stringify(variables);
        cache.modify({
          fields: {
            intervalSlacks: (existing, { storeFieldName }) => {
              /**
               * Modifier function to update the cached query `intervalSlacks`.
               *
               * Queries may be in the cache multiple times if they were called with
               * different arguments. This callback function will be called on each of
               * those cached fields so we check whether the variables we are interested
               * in is contained in the `storeFieldName` which is the full key that includes
               * the serialized variables. N.B. checking if a string includes a substring
               * means that the order of the variables and the type (int vs string) matter.
               * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
               * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
               *
               * @param {SlackNode[]} existing the array of object currently in the cache.
               * @param {String} storeFieldName the serialized full key of the field including variable arguments
               *
               * @returns {SlackNode[]} the new object appended to existing or the existing array.
               */
              if (storeFieldName.includes(strVariables)) {
                const newSlackRef = cache.writeFragment({
                  data: element,
                  fragment: SLACK_FRAGMENT,
                });
                if (!ref.current.slackEvents[formatted]) {
                  ref.current.slackEvents[formatted] = {};
                }
                ref.current.slackEvents[formatted][element.id] = element;
                return [...existing, newSlackRef];
              } else {
                return existing;
              }
            },
          },
        });
      });
    },
    onCompleted(d) {
      updateScheduleEvent(
        ref.current,
        d.saveShiftChanges.shift,
        quickInfoProp,
        selectedOffice.name,
      );
      ref.current.refreshTemplates("dateHeaderTemplate");
    },
    onError(e) {
      console.error("ERROR", e);
    },
  });

  const [callIn] = useMutation(EMPLOYEE_CALL_IN, {
    onCompleted(d) {},
    onError(error) {
      console.error(error);
    },
  });

  const handleSubmit = () => {
    if (isManager) {
      saveShiftChanges({
        variables: {
          shift: quickInfoProp?.shiftId,
          drops: [quickInfoProp?.employee.id],
        },
      });
    } else {
      callIn({
        variables: {
          id: parseInt(quickInfoProp?.employee.shiftAssignmentId),
        },
      });
    }
    closeDialog();
  };

  const empFullName = `${quickInfoProp?.employee.firstName} ${quickInfoProp?.employee.lastName}`;

  const shiftAssignmentRange = `${transformDate(quickInfoProp?.start)} ${formatTime(new Date(quickInfoProp?.start))} to ${transformDate(quickInfoProp?.end)} ${formatTime(new Date(quickInfoProp?.start))}`;

  return (
    <Grid className={classes.container}>
      <Grid item xs={10}>
        <Typography className={classes.header} component="h1" variant="h2">
          {isManager ? "Call Off Employee" : "Call Off"}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          color="secondary"
          onClick={closeDialog}
          size="large"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderWidth: 1.5 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.text}>
          {isManager ? (
            <span>
              Are you sure you want to remove <b>{empFullName}</b> from{" "}
              <b>{shiftAssignmentRange}</b>
            </span>
          ) : (
            "Call off from this shift?"
          )}
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
            className={classes.button}
          >
            Call Off
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default CallOffShift;
