import { userVar } from "../../../cache";
import { getLocalStorageItem } from "../../../hooks/storage/useStorage";

// REMEMBER: When updating baseFilterObj also update filteringNotRequired function
export function baseFilterObj() {
  return {
    requestsFilters: [
      { name: "All Requests", checked: true },
      { name: "Pending", checked: true },
      { name: "Approved", checked: true },
      { name: "Soft Requests", checked: true },
    ],
    shiftsFilters: [
      { name: "All Shifts", checked: true },
      { name: "Personal", checked: false },
    ],
    jobTypeFilters: [{ name: "All Skills", checked: true }],
    employeeFilters: [{ name: "All Employees", id: "0", checked: true }],
  };
}

const nonLeaveTypeFilters = [
  "All Requests",
  "Pending",
  "Approved",
  "Soft Requests",
];

function filteringNotRequired(filters) {
  return (
    filters.requestsFilters[0].checked &&
    filters.shiftsFilters[0].checked &&
    filters.jobTypeFilters[0].checked &&
    filters.employeeFilters[0].checked
  );
}

function filterLeaveTypeRequired(leaveTypeFilters) {
  for (const leaveTypeFilter of leaveTypeFilters) {
    if (!leaveTypeFilter.checked) {
      return true;
    }
  }
  return false;
}

function filterLeave(filters, events) {
  /**
   * Filters all events based on the current state of active filters.
   *
   * @param {[Object]}           filters array of leave type filter objects where checked is true
   * @param {[TransformedEvent]} events EMPLOYEEAVAILABILITY events
   *
   * @returns {[TransformedEvent]} all events that meet the current filters
   */
  if (!filterLeaveTypeRequired(filters)) {
    return events;
  }
  const leaveTypeObj = {};
  for (const filter of filters) {
    leaveTypeObj[filter.name] = filter.checked;
  }
  return events.filter((event) => leaveTypeObj[event.type.name]);
}

function filterScheduleSkills(skillFilters, events) {
  /**
   * Filters all events based on the current state of active filters.
   *
   * @param {[Object]}           skillFilters array of skill filter objects where checked is true
   * @param {[TransformedEvent]} events SHIFTASSIGNMENT events
   *
   * @returns {[TransformedEvent]} all events that meet the current filters
   */
  if (skillFilters[0].checked) {
    return events;
  }
  const skillFilterList = skillFilters.reduce((accumulator, currentValue) => {
    if (currentValue.checked) {
      accumulator.push("," + currentValue.skillId + ",");
    }
    return accumulator;
  }, []);
  const shiftAssignmentEvents = events.filter((event) => {
    for (var skill of skillFilterList) {
      if (event.employee.filterSkills.includes(skill)) {
        return true;
      }
    }
    return false;
  });
  return shiftAssignmentEvents;
}

function filterOffPrefs(requestsFilters, offPrefEvents) {
  /**
   * Filters offPreference events based on the current state of active filters.
   *
   * @param {[Object]}           requestsFilters array of time off filter objects where checked is true
   * @param {[TransformedEvent]} events OFFPREFERENCE events
   *
   * @returns {[TransformedEvent]} all events that meet the current filters
   */
  const offPrefFilter = requestsFilters.find(
    (filter) => filter.name === "Soft Requests",
  );
  if (offPrefFilter.checked) {
    return offPrefEvents;
  }
  return [];
}

function filterEmployees(filters, events) {
  /**
   * Filters all events based on which employee filters are applied and splits events
   * based on type for further processing.
   *
   * @param {Object}             filters custom object storing the current state of applied filters
   * @param {[TransformedEvent]} events aggregated schedule data returned from transformers
   *
   * @returns {Object} arrays of transformed events with keys: shiftAssignmentEvents, noProcessing, approved, pending
   */

  let eventsObject = {
    shiftAssignmentEvents: [],
    noProcessing: [],
    offPreferences: [],
    approved: [],
    pending: [],
    shifts: [],
  };
  let employeeFiltersMap = {};
  if (filters.shiftsFilters[1].checked) {
    filters.employeeFilters.forEach((employeeFilter) => {
      employeeFiltersMap[employeeFilter.id] = employeeFilter.id == userVar().id;
    });
  } else {
    filters.employeeFilters.forEach((employeeFilter) => {
      employeeFiltersMap[employeeFilter.id] = employeeFilter.checked;
    });
  }
  events.forEach((event) => {
    if (event.eventType === "SHIFT") {
      eventsObject.shifts.push(event);
    } else if (
      // True if (All Employees is checked and Personal is not) or the employee tied to this event is checked true
      (filters.employeeFilters[0].checked &&
        !filters.shiftsFilters[1].checked) ||
      employeeFiltersMap[event.employee.id]
    ) {
      if (event.eventType === "SHIFTASSIGNMENT") {
        eventsObject.shiftAssignmentEvents.push(event);
      } else if (
        event.eventType === "EMPLOYEEAVAILABILITY" &&
        event.isApproved
      ) {
        eventsObject.approved.push(event);
      } else if (event.eventType === "EMPLOYEEAVAILABILITY") {
        eventsObject.pending.push(event);
      } else if (event.eventType === "OFFPREFERENCE") {
        eventsObject.offPreferences.push(event);
      } else if (event.eventType === "ONCALL") {
        eventsObject.noProcessing.push(event);
      }
    }
  });
  return eventsObject;
}

export function applyFilters(filters, events) {
  /**
   * Filters all events based on the current state of active filters.
   *
   * @param {Object}             filters custom object storing the current state of applied filters
   * @param {[TransformedEvent]} events aggregated schedule data returned from transformers
   *
   * @returns {[TransformedEvent]} all events that meet the current filters
   */

  if (!filters) {
    console.warn("applyFilters : No filters available");
    return events;
  }
  if (filteringNotRequired(filters)) {
    return events;
  }

  const eventBins = filterEmployees(filters, events);

  eventBins.shiftAssignmentEvents = filterScheduleSkills(
    filters.jobTypeFilters,
    eventBins.shiftAssignmentEvents,
  );
  eventBins.offPreferences = filterOffPrefs(
    filters.requestsFilters,
    eventBins.offPreferences,
  );
  const pendingFilter = filters.requestsFilters.find(
    (filter) => filter.name === "Pending",
  );

  const leaveTypeFilters = filters.requestsFilters.filter(
    (filterObj) => !nonLeaveTypeFilters.includes(filterObj.name),
  );
  if (!pendingFilter.checked) {
    eventBins.pending = [];
  } else {
    eventBins.pending = filterLeave(leaveTypeFilters, eventBins.pending);
  }
  const approvedFilter = filters.requestsFilters.find(
    (filter) => filter.name === "Approved",
  );
  if (!approvedFilter.checked) {
    eventBins.approved = [];
  } else {
    eventBins.approved = filterLeave(leaveTypeFilters, eventBins.approved);
  }

  return Object.values(eventBins).flat();
}

export function applyOfficeFilters(selectedOfficeName, events) {
  if (!selectedOfficeName) {
    console.warn("applyOfficeFilters : No selected office available");
    return events;
  }
  let filters = getLocalStorageItem(`filter:${selectedOfficeName}:v0`);

  let displayedEvents = applyFilters(filters, events);

  return displayedEvents;
}
