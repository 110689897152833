import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  CssBaseline,
  InputAdornment,
  IconButton,
  Paper,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@mui/icons-material/Error";
import { useMutation, useApolloClient } from "@apollo/client";
import { UPDATE_PASSWORD, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { isLoggedInVar, userVar, CognitoUserVar } from "../../cache";
import { useHistory } from "react-router-dom";
import { confirmSignIn } from "aws-amplify/auth";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "160px",
    display: "flex",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "450px",
    margin: "auto",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "40px",
    height: "40px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  inputWidth: {
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  password: Yup.string().required("Required"),
  //confirmPassword: Yup.string().required("Required"),
});

const FirstTimeUserPassword = ({ user }) => {
  const history = useHistory();
  const client = useApolloClient();
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [showPassword, setShowPassword] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onError(error) {
      toastShow(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_PASSWORD Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  async function updateTemporaryPassword(newPassword) {
    const { isSignedIn, nextStep } = await confirmSignIn({
      challengeResponse: newPassword,
    });
    console.log(isSignedIn);
    console.log(nextStep);
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <main variant="body">
        <Grid className={classes.root}>
          <CssBaseline />
          <Grid className={classes.paper} component={Paper}>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                updateTemporaryPassword(values.password)
                  .then((user) => {
                    isLoggedInVar(false);
                    userVar({});
                    sessionStorage.removeItem("jwt");
                    sessionStorage.clear("jwt");
                    client.cache.reset();
                    history.push("/");
                  })
                  .catch((e) => {
                    toastShow(
                      "Please Make sure the password has one special character, one number and one upper case letter.Please try again",
                      "Error",
                    );
                  });
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item align="center">
                      <Typography variant="h3">Change Password</Typography>
                    </Grid>
                    <Grid item>
                      <Grid>
                        <Typography variant="subtitle2">
                          New Password*
                        </Typography>
                      </Grid>
                      <TextField
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        placeholder="New Password"
                        className={classes.inputWidth}
                        error={!errors.password === null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                size="large"
                              >
                                {errors.password != null ? (
                                  <ErrorIcon color="primary" />
                                ) : showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.email}
                      </Typography>
                    </Grid>

                    <Grid item align="center" mt={8}>
                      <Button type="submit" variant="contained" color="primary">
                        Change Password
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default FirstTimeUserPassword;
