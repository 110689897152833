import React, { useEffect, useState, forwardRef } from "react";
import {
  Grid,
  IconButton,
  Button,
  TextField,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  Typography,
  List,
  ListItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { Formik, FieldArray, Form } from "formik";
import { SPLIT_SHIFT } from "../../api/gqlQueries";
import { addHours, differenceInHours } from "date-fns";
import { useMutation, gql, InMemoryCache } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { add, format } from "date-fns";
import { Warning } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { orderShifts } from "../../helpers/formatShiftEvents";
import { createSplitShiftEvent } from "./UpdateEvents";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
//import { addDataToCurrentScheduleData } from "./AddDataToCurrentScheduleData";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
  commentBox: {
    width: "80%",
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20px",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20px",
  },
}))(TableCell);

/*remove an employee from a shift
add an employee to a shift
*/

const SplitShiftTable = forwardRef((props, ref) => {
  const {
    shiftEvent,
    SetShowShiftSplit,
    notifyDevelopers,
    splitShifts,
    closeDialog,
    events,
    environment,
    selectedOffice,
  } = props;

  const [showDialog, SetShowDialog] = useState(false);
  const [showGapsDialog, SetShowGapsDialog] = useState(false);
  const [values, SetValues] = useState();
  const [dialogMessage, SetDialogMessage] = useState("");
  const [gapsMessage, SetGapsMessage] = useState("");
  const [overlapsMessage, SetOverlapsMessage] = useState("");
  const [workHoursMessage, SetWorkHoursMessage] = useState("");
  const [hasChildShifts, SetHasChildShifts] = useState(false);

  let durationOfShift = differenceInHours(shiftEvent.end, shiftEvent.start);

  useEffect(() => {
    let childShiftsPresent = events.find(
      (e) => e.parentShiftId === shiftEvent.idOfShift,
    );
    childShiftsPresent && SetHasChildShifts(true);
  }, [events]);

  let splitsInHalf = [
    {
      ...shiftEvent,
      start: shiftEvent.start,
      end: add(shiftEvent.start, { hours: parseInt(durationOfShift) / 2 }),
      workHours: parseInt(durationOfShift) / 2,
    },
    {
      ...shiftEvent,
      start: add(shiftEvent.start, { hours: parseInt(durationOfShift) / 2 }),
      end: shiftEvent.end,
      workHours: parseInt(durationOfShift) / 2,
    },
  ];

  const [splitShift] = useMutation(SPLIT_SHIFT, {
    update(cache, { data: { splitShift } }) {
      /**
       * Update callback that is run after the mutation succeeds.
       *
       * Callback in order to modify the cache. The returned objects are normalized and
       * stored in the cache, but any existing queries need to be updated to include the
       * newly created shifts. Otherwise, the next time the query is executed, it will hit
       * the cache but these shifts will not be included causing them to disappear from
       * the calendar.
       * https://www.apollographql.com/docs/react/data/mutations#the-update-function
       *
       * @param {InMemoryCache} cache provides access to the apollo/client cache API
       * @param {Object} unnamed has a data property with the result of the mutation.
       */
      const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
      splitShift.newShifts.forEach((element) => {
        const start = new Date(element.start);
        const dates = paginator.getQueryDateRanges(start, start);
        const variables = {
          office: selectedOffice.id,
          pageEnd: dates[0][1].toISOString(),
          pageStart: dates[0][0].toISOString(),
        };
        const strVariables = JSON.stringify(variables);
        cache.modify({
          fields: {
            sqlShiftAssignments2: (existing, { storeFieldName }) => {
              /**
               * Modifier function to update the cached query `sqlShiftAssignments2`.
               *
               * Queries may be in the cache multiple times if they were called with
               * different arguments. This callback function will be called on each of
               * those cached fields so we check whether the variables we are interested
               * in is contained in the `storeFieldName` which is the full key that includes
               * the serialized variables. N.B. checking if a string includes a substring
               * means that the order of the variables and the type (int vs string) matter.
               * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
               * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
               *
               * @param {EfficientShiftNode[]} existing the array of object currently in the cache.
               * @param {String} storeFieldName the serialized full key of the field including variable arguments
               *
               * @returns {EfficientShiftNode[]} the new object appended to existing or the existing array.
               */
              if (storeFieldName.includes(strVariables)) {
                const newShiftRef = cache.writeFragment({
                  data: element,
                  fragment: gql`
                    fragment NewShift on EfficientShiftNode {
                      id
                      start
                      end
                      shiftDefinitionId
                      rootShiftId
                      parentShiftId
                      officeName
                      shiftTypeName
                      description
                      procedureId
                      procedureName
                      procedureType
                      shiftassignmentSet {
                        id
                        employee
                        employeeId
                        firstName
                        lastName
                      }
                    }
                  `,
                });
                return [...existing, newShiftRef];
              } else {
                return existing;
              }
            },
          },
        });
      });
      splitShift.slacks.forEach((element) => {
        const start = new Date(element.interval.start);
        const formatted = format(start, "MM/dd/yyyy");
        const dates = paginator.getQueryDateRanges(start, start);
        const variables = {
          issuesOnly: true,
          office: selectedOffice.id,
          pageEnd: dates[0][1].toISOString(),
          pageStart: dates[0][0].toISOString(),
        };
        const strVariables = JSON.stringify(variables);
        console.log(`String Variables: ${strVariables}`);
        cache.modify({
          fields: {
            intervalSlacks: (existing, { storeFieldName }) => {
              /**
               * Modifier function to update the cached query `intervalSlacks`.
               *
               * Queries may be in the cache multiple times if they were called with
               * different arguments. This callback function will be called on each of
               * those cached fields so we check whether the variables we are interested
               * in is contained in the `storeFieldName` which is the full key that includes
               * the serialized variables. N.B. checking if a string includes a substring
               * means that the order of the variables and the type (int vs string) matter.
               * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
               * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
               *
               * @param {SlackNode[]} existing the array of object currently in the cache.
               * @param {String} storeFieldName the serialized full key of the field including variable arguments
               *
               * @returns {SlackNode[]} the new object appended to existing or the existing array.
               */
              console.log(`Store Field Name: ${storeFieldName}`);
              if (storeFieldName.includes(strVariables)) {
                console.log("Entered SFN if statement");
                const newSlackRef = cache.writeFragment({
                  data: element,
                  fragment: gql`
                    fragment NewSlack on SlackNode {
                      id
                      required
                      slack
                      numAssigned
                      interval {
                        id
                        start
                        end
                        shifts {
                          id
                          start
                          end
                          procedure {
                            id
                            procedureRequirement {
                              id
                              name
                            }
                          }
                          office {
                            id
                            name
                          }
                        }
                      }
                      skill {
                        id
                        name
                      }
                    }
                  `,
                });

                if (!ref.current.slackEvents[formatted]) {
                  ref.current.slackEvents[formatted] = {};
                }
                ref.current.slackEvents[formatted][element.id] = element;
                return [...existing, newSlackRef];
              } else {
                return existing;
              }
            },
          },
        });
      });
    },
    onCompleted(d) {
      createSplitShiftEvent(
        ref.current,
        d.splitShift.newShifts,
        selectedOffice.name,
      );
      SetShowDialog(false);
      closeDialog();
      SetGapsMessage("");
      SetOverlapsMessage("");
      SetWorkHoursMessage("");
      toastShow("Shift Splits Created", "Success");
      ref.current.refreshTemplates("dateHeaderTemplate");
    },
    onError(err) {
      console.log(err);
      toastShow("Error Edit Shift. Graphql " + err, "Error");
      notifyDevelopers({
        variables: {
          message:
            "Error on SPLIT_SHIFT Mutation. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  if (!events) {
    <CircularProgress />;
  } else {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item container direction="column">
            <Dialog open={showDialog} fullWidth maxWidth="sm">
              <DialogContent
                style={{
                  padding: 20,
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography variant="h4">
                      Are you sure you want to create these splits for the
                      selected shift?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <List>
                      {values &&
                        values.length > 0 &&
                        values.map((e, ind) => (
                          <ListItem key={ind}>
                            {"-  " +
                              format(new Date(e.start), "dd-MMM-yyyy HH:mm") +
                              " to " +
                              format(new Date(e.end), "dd-MMM-yyyy HH:mm")}
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Grid>

                {gapsMessage != "" && (
                  <Grid container spacing={1}>
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                      <Warning />
                    </Grid>
                    <Grid item xs={11} style={{ textAlign: "left" }}>
                      <Typography>{gapsMessage}</Typography>
                    </Grid>
                  </Grid>
                )}
                {overlapsMessage != "" && (
                  <Grid container spacing={1}>
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                      <Warning />
                    </Grid>
                    <Grid item xs={11} style={{ textAlign: "left" }}>
                      <Typography>{overlapsMessage}</Typography>
                    </Grid>
                  </Grid>
                )}
                {workHoursMessage != "" && (
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Warning />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>{workHoursMessage}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Button
                  onClick={() => {
                    splitShift({
                      variables: {
                        newShifts: values.map((e) => {
                          return {
                            start: e.start,
                            end: e.end,
                            workHours: e.workHours,
                          };
                        }),
                        shiftId: parseInt(shiftEvent.shiftId),
                      },
                    });
                  }}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    SetGapsMessage("");
                    SetOverlapsMessage("");
                    SetWorkHoursMessage("");
                    SetShowDialog(false);
                  }}
                  color="primary"
                  variant="outlined"
                >
                  No
                </Button>
              </DialogContent>
            </Dialog>

            <Dialog open={showGapsDialog} fullWidth maxWidth="sm">
              <DialogContent
                style={{
                  padding: 20,
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Warning />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography>{dialogMessage}</Typography>
                  </Grid>
                </Grid>

                <Button
                  onClick={() => {
                    SetDialogMessage("");
                    SetShowGapsDialog(false);
                  }}
                  color="primary"
                  variant="contained"
                >
                  OK
                </Button>
              </DialogContent>
            </Dialog>
            <Grid
              item
              container
              justifyContent="space-between"
              alignContent="flex-start"
            >
              <Grid item xs={11}>
                <Button variant="text" onClick={() => SetShowShiftSplit(false)}>
                  Back to Edit Shift
                </Button>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  color="secondary"
                  onClick={closeDialog}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item>
                <Formik
                  enableReinitialize
                  initialValues={{
                    splitShifts: hasChildShifts ? splitShifts : splitsInHalf,
                  }}
                  onSubmit={(values) => {
                    SetValues(values.splitShifts);
                    let gaps = [];
                    let overlaps = [];
                    let workHours = [];
                    let totalWorkHoursOfSplits = 0;
                    values.splitShifts.map((e, index, arr) => {
                      totalWorkHoursOfSplits =
                        totalWorkHoursOfSplits + parseInt(e.workHours);
                      if (
                        arr[index + 1] &&
                        new Date(arr[index].end) <
                          new Date(arr[index + 1].start)
                      ) {
                        gaps.push({
                          start: new Date(arr[index].end),
                          end: new Date(arr[index + 1].start),
                        });
                      }
                      if (
                        arr[index + 1] &&
                        new Date(arr[index].end) >
                          new Date(arr[index + 1].start)
                      ) {
                        overlaps.push({
                          start: new Date(arr[index + 1].start),
                          end: new Date(arr[index].end),
                        });
                      }
                      if (
                        parseInt(e.workHours) <
                        parseInt(differenceInHours(e.end, e.start))
                      ) {
                        workHours.push(e);
                      }
                    });
                    if (
                      parseInt(totalWorkHoursOfSplits) >
                      parseInt(shiftEvent.workHours)
                    ) {
                      SetDialogMessage(
                        "Total work hours of the splits combined is greater than the total work hours of the shift.",
                      );
                      setTimeout(() => {
                        SetShowGapsDialog(true);
                      }, 500);
                    } else {
                      if (gaps.length > 0) {
                        SetGapsMessage(
                          "There is a gap in the splits in these times - " +
                            gaps.map(
                              (e) =>
                                format(e.start, "HH:mm") +
                                " to " +
                                format(e.end, "HH:mm"),
                            ),
                        );
                      } else if (overlaps.length > 0) {
                        SetOverlapsMessage(
                          "There is an overlap in the splits - " +
                            overlaps.map(
                              (e) =>
                                format(e.start, "HH:mm") +
                                " to " +
                                format(e.end, "HH:mm"),
                            ),
                        );
                      } else if (workHours.length > 0) {
                        SetWorkHoursMessage(
                          "The number of work hours for the splits is lesser than the duration of the split - " +
                            workHours.map(
                              (e) =>
                                format(e.start, "HH:mm") +
                                " to " +
                                format(e.end, "HH:mm"),
                            ),
                        );
                      }
                      setTimeout(() => {
                        SetShowDialog(true);
                      }, 1000);
                    }
                  }}
                >
                  {({ values, handleChange, dirty, setFieldValue }) => (
                    <Form>
                      <Grid container direction="row" spacing={4}>
                        <Grid item>
                          {" "}
                          <FieldArray name="splitShifts">
                            {({ push, remove }) => (
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      colSpan={3}
                                    ></StyledTableCell>
                                  </TableRow>
                                  <TableRow>
                                    <StyledTableCell>Start</StyledTableCell>
                                    <StyledTableCell>End</StyledTableCell>
                                    <StyledTableCell>Hours</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.splitShifts &&
                                    values.splitShifts.length > 0 &&
                                    values.splitShifts.map(
                                      (element, index, arr) => (
                                        <TableRow key={index}>
                                          <StyledTableCell>
                                            <DateTimePicker
                                              variant="outlined"
                                              name={`splitShifts.${index}.start`}
                                              id={`splitShifts.${index}.start`}
                                              value={new Date(element.start)}
                                              onChange={(ev) => {
                                                console.log(ev);
                                                console.log(element);
                                                setFieldValue(
                                                  `splitShifts.${index}.start`,
                                                  ev,
                                                );
                                                setFieldValue(
                                                  `splitShifts.${index}.workHours`,
                                                  differenceInHours(
                                                    element.end,
                                                    ev,
                                                  ),
                                                );
                                              }}
                                              minDateTime={shiftEvent.start}
                                              maxDateTime={shiftEvent.end}
                                              renderInput={(props) => {
                                                return <TextField {...props} />;
                                              }}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <DateTimePicker
                                              variant="outlined"
                                              name={`splitShifts.${index}.end`}
                                              id={`splitShifts.${index}.end`}
                                              value={new Date(element.end)}
                                              minDateTime={shiftEvent.start}
                                              maxDateTime={shiftEvent.end}
                                              onChange={(ev) => {
                                                arr[index + 1]
                                                  ? setFieldValue(
                                                      `splitShifts.${index + 1}.start`,
                                                      ev,
                                                    )
                                                  : "";
                                                setFieldValue(
                                                  `splitShifts.${index}.end`,
                                                  ev,
                                                );
                                                setFieldValue(
                                                  `splitShifts.${index}.workHours`,
                                                  differenceInHours(
                                                    ev,
                                                    element.start,
                                                  ),
                                                );
                                                arr[index + 1]
                                                  ? setFieldValue(
                                                      `splitShifts.${index + 1}.workHours`,
                                                      differenceInHours(
                                                        values.splitShifts[
                                                          values.splitShifts
                                                            .length - 1
                                                        ].end,
                                                        ev,
                                                      ),
                                                    )
                                                  : 0;
                                              }}
                                              renderInput={(props) => {
                                                return <TextField {...props} />;
                                              }}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <TextField
                                              type="number"
                                              variant="outlined"
                                              name={`splitShifts.${index}.workHours`}
                                              id={`splitShifts.${index}.workHours`}
                                              value={
                                                element.workHours
                                                  ? element.workHours
                                                  : differenceInHours(
                                                      element.end,
                                                      element.start,
                                                    )
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `splitShifts.${index}.workHours`,
                                                  e.target.value,
                                                )
                                              }
                                              InputProps={{
                                                inputProps: {
                                                  min: 0.25,
                                                  step: 0.25,
                                                  max: differenceInHours(
                                                    element.end,
                                                    element.start,
                                                  ),
                                                },
                                              }}
                                            />
                                          </StyledTableCell>
                                        </TableRow>
                                      ),
                                    )}
                                  <TableRow>
                                    <StyledTableCell colSpan={3}>
                                      <Button
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                          push({
                                            start:
                                              values.splitShifts &&
                                              values.splitShifts.length > 0
                                                ? values.splitShifts[
                                                    values.splitShifts.length -
                                                      1
                                                  ].end
                                                : shiftEvent.start,
                                            end: shiftEvent.end,
                                            workHours: differenceInHours(
                                              shiftEvent.end,
                                              values.splitShifts[
                                                values.splitShifts.length - 1
                                              ].end,
                                            ),
                                          });
                                        }}
                                      >
                                        Add Another
                                      </Button>
                                    </StyledTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            )}
                          </FieldArray>
                        </Grid>
                      </Grid>
                      <Box m={4} style={{ textAlign: "right" }}>
                        <Button
                          type="submit"
                          disabled={hasChildShifts && !dirty}
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </>
    );
  }
});

export default SplitShiftTable;
