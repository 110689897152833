import React, { useEffect, useState, lazy, Suspense } from "react";
import clsx from "clsx";
import Dashboard from "../Dashboard/Dashboard";
import ShiftBasedLanding from "../calendar/Landing";
import MissionBasedLanding from "../missionBasedCalendar/Landing";
import Notifications from "../notifications/Notifications";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { Switch, Route, useHistory } from "react-router-dom";
import {
  Container,
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Roles from "../../Roles/roles";
import AppSettings from "../admin/AppSettings";
import {
  userVar,
  appsettingsVar,
  locationConfigVar,
  officeConfigVar,
  notificationsVar,
  ShiftDefinitionsVar,
} from "../../cache";
import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import {
  GET_NOTIFICATION_COUNT,
  GET_USER_NAME,
  ABILITIES,
  PUBLICLOCATION,
  LOCATION,
  NOTIFY_DEVELOPERS,
  ALL_SHIFT_DEFINITIONS,
  ALL_OFFICES_DETAILS,
} from "../../api/gqlQueries";
import DefineAbility from "../../Roles/defineAbility";
import { useIdleTimer } from "react-idle-timer";
import { mapHiredShifts } from "../../helpers/hiredShifts";
import LogRocket from "logrocket";
import { getCurrentUser } from "aws-amplify/auth";
import { handleLogout } from "./LogOutDialog";
import UserIconInfo from "./UserIconInfo";
import OfficeContext from "./OfficeContext";
import DrawerComponent from "./DrawerComponent";
import NotificationPopover from "./NotificationPopOver";
import { useStatefulSessionStorage } from "../../hooks/storage/useStorage";
import "../../AllStyles/Toast.css";
import Greeting from "../Dashboard/Greeting";
import PayrollReportPage from "../Reports/PayrollReport";
import { ErrorBoundary } from "react-error-boundary";
import ScheduleSummaryPage from "../Analytics/ScheduleSummaryPage";
const Preferences = lazy(() => import("../preferences/Preferences"));
const ManageUsers = lazy(() => import("../mangUsers/MangTeam"));
const ImportData = lazy(() => import("../importData/ImportData"));
const ManageOffice = lazy(() => import("../admin/ManageOffice"));
const Profile = lazy(() => import("../profile/Profile"));
const FirstTimeUser = lazy(() => import("../userAuthentication/firstTimeUser"));
const OfficeSetupGuide = lazy(() => import("../admin/OfficeSetupGuide"));
const LocationSettings = lazy(() => import("../admin/LocationSettings"));
const RolesandPermissions = lazy(() => import("../admin/RolesandPermissions"));
const AnalyticsOverview = lazy(() => import("../Analytics/AnalyticsOveview"));
const PreferenceDashboard = lazy(
  () => import("../Analytics/PreferenceDashboard"),
);
const SkillSetDashboard = lazy(() => import("../Analytics/SkillSetDashboard"));
const TimeOffDashboard = lazy(() => import("../Analytics/TimeOffAnalytics"));
const FloatSettings = lazy(() => import("../floating/FloatSettings"));
const FloatNeeds = lazy(() => import("../floating/FloatNeeds"));
const DepartmentStatus = lazy(() => import("../floating/FloatNeeds"));
const CreateSchedule = lazy(() => import("../createSchedule/CreateSchedule"));
const AllProcedures = lazy(() => import("../admin/AllProcedures"));
const ProcedureEmployeeTemplates = lazy(
  () => import("../admin/ProcedureEmployeeTemplates"),
);
const Logout = lazy(() => import("./LogOutDialog"));
const Requests = lazy(() => import("../requests/Requests"));
const FloatAnalytics = lazy(() => import("../Analytics/FloatAnalytics"));
const LocationAnalytics = lazy(
  () => import("../Analytics/LocationPreferenceDashboard"),
);
const SlackReport = lazy(() => import("../Reports/SlackReport"));
const PayrollReport = lazy(() => import("../Reports/PayrollReport"));
const SkillSetReport = lazy(() => import("../Reports/SkillSetReport"));
const MangMentees = lazy(() => import("../MangMentees/mentee"));
const EmployeeAllocation = lazy(() => import("../floating/EmployeeAllocation"));

let toastObj;
function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const ProcedureTemplateOfTemplates = lazy(
  () => import("../admin/ProcedureTemplateofTemplates"),
);

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // overflowY: "hidden",
    // msOverflowY: "hidden",
    backgroundColor: "#f9f9f9",
  },
  mainContainer: {
    marginBottom: "50px",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  dividerColor: {
    border: "0.5px solid#ffffff",
    height: "50px",
    marginRight: "20px",
    marginLeft: "20px",
  },
  appBar: {
    backgroundColor: "#ffffff",
    zIndex: theme.zIndex.Drawer - 1,
    position: "fixed",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 10,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    // overflowY: "hidden",
  },
  drawerPaper: {
    position: "absolute",
    paddingTop: 50,
    flexGrow: 1,
    bottom: 20,
    backgroundColor: "#595a5c",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    position: "sticky",
    paddingTop: 50,
    overflow: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}));

function AuthenticatedApp() {
  const client = useApolloClient();
  const classes = useStyles();
  const history = useHistory();

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(err) {
      console.error(err);
    },
  });

  const locationSettings = useQuery(PUBLICLOCATION, {
    onCompleted(d) {
      appsettingsVar({ color: d.locationPublicSettings[0].hexcolor });
    },
    onError(error) {
      console.error(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PUBLICLOCATION Query. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  useQuery(ALL_SHIFT_DEFINITIONS, {
    onCompleted(d) {
      ShiftDefinitionsVar(d.shiftDefinitions);
    },
    onError(e) {
      console.error(e.message);
    },
  });

  const allOffices = useQuery(ALL_OFFICES_DETAILS, {
    onError(e) {
      if (e.message.includes("No key found for this token")) {
        console.error(error.message);
        handleLogout(client);
      }
    },
  });

  const locationConfig = useQuery(LOCATION, {
    onError(err) {
      console.error(err);
      if (err.message.includes("No key found for this token")) {
        console.error(err.message);
        handleLogout(client);
      } else {
        toastShow(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on LOCATION Query. Environment: " +
              environment +
              " Graphql " +
              error,
          },
        });
      }
    },
  });

  const [logourl, setlogourl] = useState("");
  const [windowHeight, SetWindowHeight] = useState(
    parseInt(window.innerHeight) + 50,
  );

  useEffect(() => {
    window.addEventListener("resize", () =>
      SetWindowHeight(window.innerHeight),
    );
  }, []);

  const [authenticatedUser, SetAuthenticatedUser] = useState(null);

  async function getUserDetails() {
    try {
      await getCurrentUser().then((user) => {
        SetAuthenticatedUser(user);
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  const currentLogo =
    !locationSettings.loading &&
    locationSettings.data &&
    locationSettings.data.locationPublicSettings[0].logo;

  const appSettings = appsettingsVar();
  appSettings.logo = currentLogo;
  const baseURL = window.location.origin;
  const environment = process.env.NODE_ENV;

  const [openRefreshDialog, setOpenRefreshDialog] = useState(false);

  const handleIdle = () => {
    setOpenRefreshDialog(true);
  };

  const { getElapsedTime } = useIdleTimer({
    timeout: 1000 * 60 * 20, //20 minutes
    onIdle: handleIdle,
    stopOnIdle: false,
  });

  useEffect(() => {
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [!locationSettings.loading]);

  useEffect(() => {
    if (environment === "development") {
      setlogourl("https://dev.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [appSettings.logo]);

  const [userDetails, SetUserDetails] = useState();

  const [selectedOfficeId, setSelectedOfficeId] = useStatefulSessionStorage(
    "selectedOfficeId",
    null,
  );
  const [selectedOffice, setSelectedOffice] = useStatefulSessionStorage(
    "selectedOffice",
    null,
  );

  const { loading, error, data } = useQuery(GET_USER_NAME, {
    onCompleted(d) {
      const user = d.me;
      SetUserDetails(d.me);
      LogRocket.identify(user.id, {
        email: d.me.email,
      });
      const userVariable = userVar();
      userVar({
        ...userVariable,
        alwaysOpenGuide: user.alwaysOpenGuide,
        showPrefsExample: user.showPrefsExample,
      });
    },
    onError(error) {
      if (error.message.includes("No key found for this token")) {
        handleLogout(client);
      } else {
        toastShow(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_USER_NAME. Environment: " +
              environment +
              " Graphql " +
              error,
          },
        });
      }
    },
  });

  if (data && allOffices) {
    let primaryOffice = data.me.assignmentSet.find(
      (assignment) => assignment.primary === true,
    ).office;
    let parentOfficeId = primaryOffice.parent?.id;

    if (selectedOfficeId == null || selectedOffice == null) {
      setSelectedOfficeId(parentOfficeId || primaryOffice.id);
      let parentOffice = parentOfficeId
        ? allOffices.data.offices.find((office) => office.id == parentOfficeId)
        : primaryOffice;
      setSelectedOffice(parentOffice);
    }
  }

  const userpermissions = useQuery(ABILITIES, {
    onCompleted(data) {
      SetuserAbilities(data.abilities);
    },
    onError(err) {
      if (err.message.includes("Signature has expired")) {
        console.error(err.message);
        handleLogout(client);
      } else {
        console.error(error);
        toastShow(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on ABILITIES Query. Environment: " +
              environment +
              " Graphql " +
              error,
          },
        });
      }
    },
  });

  const [userAbilities, SetuserAbilities] = useState({});

  const { data: notificationsData, loading: notificationsLoading } = useQuery(
    GET_NOTIFICATION_COUNT,
    {
      pollInterval: 15000,
      onError(error) {
        console.error(error);
        if (error.message.includes("Signature has expired")) {
          console.error(error.message);
          handleLogout(client);
        } else {
          toastShow(
            "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
            "Error",
          );
          notifyDevelopers({
            variables: {
              message:
                "Error on GET_NOTIFICATION_COUNT Query. Environment: " +
                environment +
                " Graphql " +
                error,
            },
          });
        }
      },
    },
  );

  if (
    loading ||
    locationSettings.loading ||
    notificationsLoading ||
    userpermissions.loading ||
    locationConfig.loading ||
    allOffices.loading ||
    !userDetails
  ) {
    return <Typography>Loading...</Typography>;
  } else if (error) {
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const notificationCount =
      notificationsData &&
      notificationsData.me &&
      notificationsData.me.notificationSet &&
      notificationsData.me.notificationSet.length > 0
        ? notificationsData.me.notificationSet.filter(
            (notification) => notification.read !== true,
          ).length
        : 0;
    notificationsVar(notificationCount);
    const offices = allOffices.data.offices;
    const primaryAssignment =
      userDetails &&
      userDetails.assignmentSet.find(
        (assignment) =>
          assignment.primary === true && assignment.endDate === null,
      );

    const secondaryOffices = userDetails.assignmentSet.filter(
      (assignment) => assignment.primary === false,
    );

    const schedulable = primaryAssignment
      ? primaryAssignment.schedulable
      : userDetails.assignmentSet[0].schedulable;
    const primaryUnit = primaryAssignment
      ? primaryAssignment.office
      : userDetails.assignmentSet[0].office;
    const userRole = primaryAssignment
      ? primaryAssignment.role.name.toUpperCase()
      : userDetails.assignmentSet[0].role.name.toUpperCase();
    const shiftType = primaryAssignment
      ? mapHiredShifts(
          primaryAssignment.hiredshiftSet,
          primaryAssignment.rotationLength,
        )
      : mapHiredShifts(
          userDetails.assignmentSet[0].hiredshiftSet,
          userDetails.assignmentSet[0].rotationLength,
        );
    const userName = userDetails.firstName + " " + userDetails.lastName;
    const shiftBasedDisplay =
      primaryAssignment.office.shiftBasedDisplay === null ||
      primaryAssignment.office.shiftBasedDisplay === "undefined"
        ? locationConfig.data.locations[0].shiftBasedDisplay
        : primaryAssignment.office.shiftBasedDisplay;
    const analyticsTab = locationConfig.data.locations[0].analyticsTab;
    locationConfigVar({
      analyticsTab: analyticsTab,
      integrationsTab: locationConfig.data.locations[0].integrationsTab,
      shiftBasedScheduling:
        primaryAssignment.office.shiftBasedDisplay === null ||
        primaryAssignment.office.shiftBasedDisplay === "undefined"
          ? locationConfig.data.locations[0].shiftBasedDisplay
          : primaryAssignment.office.shiftBasedDisplay,
      shiftBasedDisplay:
        primaryAssignment.office.shiftBasedDisplay === null ||
        primaryAssignment.office.shiftBasedDisplay === "undefined"
          ? locationConfig.data.locations[0].shiftBasedDisplay
          : primaryAssignment.office.shiftBasedDisplay,
    });

    officeConfigVar({
      softRequests: primaryAssignment.office.softRequests,
      shiftBasedDisplay:
        primaryAssignment.office.shiftBasedDisplay != null
          ? primaryAssignment.office.shiftBasedDisplay
          : shiftBasedDisplay,
      shiftTypePreferences: primaryAssignment.office.shiftTypePrefs,
      daysOfTheWeekPreferences: primaryAssignment.office.weekdaysPrefs,
      daysOffPreferences: primaryAssignment.office.daysOffPrefs,
      overTimePreferences: primaryAssignment.office.overtimePrefs,
      procedureImports: primaryAssignment.office.procedureImports,
      employeeImports: primaryAssignment.office.employeeImports,
      availabilityImports: primaryAssignment.office.availabilityImports,
      skillImports: primaryAssignment.office.skillImports,
      trainingImports: primaryAssignment.office.trainingImports,
    });

    let primaryAllowsManual = locationConfig.data.locations[0].officeSet.find(
      (e) => e.id === primaryUnit.id,
    )?.isManual;

    let isPrimaryParentOffice =
      primaryUnit.parent === null &&
      locationConfig.data.locations[0].officeSet.filter(
        (e) => e.parent && e.parent.id === primaryUnit.id,
      ).length > 0;
    let isPrimayChildOffice = primaryUnit.parent != null;

    const userObject = {
      office: primaryUnit,
      role: userRole,
      name: userName,
      id: userDetails.id,
      shiftType: shiftType,
      isEmployee: userRole === Roles.EMPLOYEE,
      isAdmin: userRole === Roles.ADMIN,
      isManager: userRole === Roles.MANAGER,
      isScheduler: userRole === Roles.SCHEDULER,
      isSchedulable: schedulable,
      userabilities: userAbilities,
      floatOffice: primaryUnit.floatStatus,
      skills: userDetails.skills,
      maxgreyOutRequests: primaryUnit.maxgreyOutRequests,
      allowCallIns: primaryUnit.allowCallIns,
      scheduleDuration: primaryUnit.scheduleDuration,
      manuallySchedulable: primaryAssignment.isManual,
      secondaryOffices: secondaryOffices,
      officeAllowsManual: primaryAllowsManual,
      isPrimaryParentOffice: isPrimaryParentOffice,
      isPrimayChildOffice: isPrimayChildOffice,
    };

    const userVariable = userVar();
    userVar({ ...userVariable, ...userObject });
    const ability = DefineAbility(userObject);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Suspense fallback={<CircularProgress />}>
          <Logout
            openRefreshDialog={openRefreshDialog}
            setOpenRefreshDialog={setOpenRefreshDialog}
          />
        </Suspense>

        <AppBar position="absolute" className={clsx(classes.appBar)}>
          <Toolbar className={classes.toolbar}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs={4}>
                <Greeting />
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <OfficeContext
                      loading={loading}
                      selectedOfficeId={selectedOfficeId}
                      allOffices={allOffices}
                      setSelectedOffice={setSelectedOffice}
                      primaryAssignment={primaryAssignment}
                      secondaryOffices={secondaryOffices}
                      selectedOffice={selectedOffice}
                      setSelectedOfficeId={setSelectedOfficeId}
                    />
                  </Grid>
                  <Grid item>
                    <NotificationPopover
                      notificationCount={notificationCount}
                      userDetails={userDetails}
                    />
                  </Grid>
                  <Grid item>
                    <UserIconInfo userDetails={userDetails} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DrawerComponent />
        <div className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.mainContainer} maxWidth={false}>
            <Box>
              <Switch>
                <>
                  {ability.can("manage", "Profile") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route path="/Profile" component={Profile} />
                    </Suspense>
                  )}
                  {
                    <Suspense fallback={<CircularProgress />}>
                      <Route
                        path="/UpdatePassword"
                        exact
                        component={() => <FirstTimeUser user={userDetails} />}
                      />
                    </Suspense>
                  }
                  {ability.can("manage", "Notifications") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route path="/Notifications" component={Notifications} />
                    </Suspense>
                  )}
                  {ability.can("manage", "Calendar") &&
                  locationConfigVar().shiftBasedDisplay === true ? (
                    <>
                      {(window.SHOW_CALENDAR_ON_LOAD === true ||
                        window.SHOW_CALENDAR_ON_LOAD === "true") &&
                      userVar().isEmployee === true ? (
                        <>
                          <Route
                            path="/"
                            exact
                            component={() => (
                              <ShiftBasedLanding
                                selectedOffice={selectedOffice}
                                allOffices={offices}
                              />
                            )}
                          />
                          <Route
                            path="/Dashboard"
                            exact
                            component={() => (
                              <Dashboard selectedOffice={selectedOffice} />
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <Route
                            path="/"
                            exact
                            component={() => (
                              <Dashboard selectedOffice={selectedOffice} />
                            )}
                          />
                          <Route
                            path="/Schedule"
                            exact
                            component={() => (
                              <ShiftBasedLanding
                                selectedOffice={selectedOffice}
                                allOffices={offices}
                              />
                            )}
                          />
                        </>
                      )}
                      <Route
                        path="/Schedule/:date/draft"
                        exact
                        component={() => (
                          <ShiftBasedLanding
                            selectedOffice={selectedOffice}
                            allOffices={offices}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/"
                        exact
                        component={() => (
                          <Dashboard selectedOffice={selectedOffice} />
                        )}
                      />
                      <Route
                        path="/Schedule"
                        exact
                        component={MissionBasedLanding}
                      />
                      <Route
                        path="/Schedule/:date/draft"
                        exact
                        component={MissionBasedLanding}
                      />
                    </>
                  )}
                  {ability.can("manage", "Calendar") && (
                    <>
                      <Suspense fallback={<CircularProgress />}>
                        <Route
                          path="/PayrollReport"
                          exact
                          component={() => (
                            <ErrorBoundary
                              fallbackRender={(props) => (
                                <PayrollReportPage
                                  selectedOffice={selectedOffice}
                                />
                              )}
                            >
                              <PayrollReportPage
                                selectedOffice={selectedOffice}
                              />
                            </ErrorBoundary>
                          )}
                        />
                        <Route
                          path="/ScheduleSummary"
                          exact
                          component={() => (
                            <ErrorBoundary
                              fallbackRender={(props) => (
                                <ScheduleSummaryPage
                                  selectedOffice={selectedOffice}
                                />
                              )}
                            >
                              <ScheduleSummaryPage
                                selectedOffice={selectedOffice}
                              />
                            </ErrorBoundary>
                          )}
                        />
                        <Route
                          path="/StaffingCoverage"
                          exact
                          component={() => (
                            <SlackReport selectedOffice={selectedOffice} />
                          )}
                        />
                        <Route
                          path="/StaffAvailability"
                          exact
                          component={() => (
                            <SkillSetReport selectedOffice={selectedOffice} />
                          )}
                        />
                      </Suspense>
                    </>
                  )}
                  {(window.HIDE_EMPLOYEE_REQUEST_FORMS === false ||
                    window.HIDE_EMPLOYEE_REQUEST_FORMS === "false") &&
                    ability.can("manage", "Preferences") &&
                    schedulable && (
                      <Suspense fallback={<CircularProgress />}>
                        <Route path="/Preferences" component={Preferences} />
                      </Suspense>
                    )}
                  {ability.can("manage", "Schedule") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route
                        path="/CreateSchedule"
                        component={() => (
                          <CreateSchedule selectedOffice={selectedOffice} />
                        )}
                      />
                    </Suspense>
                  )}
                  {ability.can("manage", "Requests") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route
                        path="/Schedule/:request"
                        exact
                        component={ShiftBasedLanding}
                      />
                    </Suspense>
                  )}

                  {ability.can("manage", "Users") && (
                    <>
                      <Suspense fallback={<CircularProgress />}>
                        <Route path="/ManageTeam" component={ManageUsers} />
                        <Route path="/ManageMentees" component={MangMentees} />
                      </Suspense>
                    </>
                  )}
                  {ability.can("manage", "Users") && (
                    <>
                      <Suspense fallback={<CircularProgress />}>
                        <Route path="/ImportData" component={ImportData} />
                      </Suspense>
                    </>
                  )}
                  {ability.can("manage", "Users") &&
                    userVariable.floatOffice && (
                      <>
                        <Suspense fallback={<CircularProgress />}>
                          <Route
                            path="/EmployeeAllocation"
                            component={EmployeeAllocation}
                          />
                        </Suspense>
                      </>
                    )}
                  {ability.can("manage", "Users") &&
                    userVariable.floatOffice &&
                    analyticsTab && (
                      <>
                        <Suspense fallback={<CircularProgress />}>
                          <Route
                            path="/FloatAnalytics"
                            component={FloatAnalytics}
                          />
                        </Suspense>
                      </>
                    )}
                  {ability.can("manage", "Users") && analyticsTab && (
                    <>
                      <Suspense fallback={<CircularProgress />}>
                        <Route
                          path="/ScheduleQualityAnalytics"
                          component={() => (
                            <AnalyticsOverview
                              selectedOffice={selectedOffice}
                            />
                          )}
                        />
                        <Route
                          path="/PreferenceAnalytics"
                          component={() => (
                            <PreferenceDashboard
                              selectedOffice={selectedOffice}
                            />
                          )}
                        />
                        {/* <Route
                          path="/SkillSetAnalytics"
                          component={() => (
                            <SkillSetDashboard
                              selectedOffice={selectedOffice}
                            />
                          )}
                        /> */}
                        {/* <Route
                          path="/TimeOffAnalytics"
                          component={() => (
                            <TimeOffDashboard selectedOffice={selectedOffice} />
                          )}
                        /> */}
                        <Route
                          path="/LocationPreferenceAnalytics"
                          component={() => (
                            <LocationAnalytics
                              selectedOffice={selectedOffice}
                            />
                          )}
                        />
                      </Suspense>
                    </>
                  )}
                  {ability.can("manage", "AllOffices") && (
                    <>
                      <Suspense fallback={<CircularProgress />}>
                        <Route
                          path="/Admin"
                          component={() => (
                            <ManageOffice
                              open={false}
                              office={null}
                              selectedOffice={selectedOffice}
                            />
                          )}
                        />
                        <Route
                          path="/SetupGuide"
                          component={OfficeSetupGuide}
                        />
                      </Suspense>

                      {shiftBasedDisplay === false && (
                        <>
                          <Suspense fallback={<CircularProgress />}>
                            <Route
                              path="/MissionDetails"
                              component={AllProcedures}
                            />
                            <Route
                              path="/ProcedureTypeTemplate"
                              component={ProcedureEmployeeTemplates}
                            />
                            <Route
                              path="/ProcedureTemplate"
                              component={ProcedureTemplateOfTemplates}
                            />
                          </Suspense>
                        </>
                      )}
                      {userObject.floatOffice && (
                        <>
                          <Suspense fallback={<CircularProgress />}>
                            <Route
                              path="/FloatSettings"
                              component={FloatSettings}
                            />
                            <Route path="/FloatNeeds" component={FloatNeeds} />
                            <Route
                              path="/DepartmentStatus"
                              component={DepartmentStatus}
                            />
                          </Suspense>
                        </>
                      )}
                    </>
                  )}
                  {ability.can("manage", "LocationSettings") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route
                        path="/LocationSettings"
                        component={LocationSettings}
                      />
                    </Suspense>
                  )}
                  {ability.can("manage", "AppSettings") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route path="/AppSettings" component={AppSettings} />
                    </Suspense>
                  )}
                  {
                    <Suspense fallback={<CircularProgress />}>
                      <Route
                        path="/Requests"
                        component={() => (
                          <Requests selectedOffice={selectedOffice} />
                        )}
                      />
                    </Suspense>
                  }
                  {ability.can("manage", "RolesandPermissions") && (
                    <Suspense fallback={<CircularProgress />}>
                      <Route
                        path="/RolesandPermissions"
                        component={RolesandPermissions}
                      />
                    </Suspense>
                  )}
                </>
              </Switch>
            </Box>
          </Container>
        </div>
      </div>
    );
  }
}

export default AuthenticatedApp;
