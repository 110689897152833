import React, { useEffect, useState } from "react";
import { Grid, Checkbox, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import StarRateIcon from "@mui/icons-material/StarRate";
import { userVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 0,
    marginRight: -9,
    marginBottom: -6,
    marginLeft: 2,
  },
}));

const EmpSelectEmployeesandDates = (props) => {
  const { rescheduleOptions, setError, setRescheduleOptionIds } = props;

  const user = userVar();

  const [checked, setChecked] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setChecked([]);
  }, [rescheduleOptions]);

  const handleCheckboxToggle = (option) => () => {
    const currentIndex = checked.indexOf(parseInt(option.optionId));
    const newChecked = [...checked];
    if (currentIndex === -1) {
      setError("");
      newChecked.push(parseInt(option.optionId));
    } else {
      setError("");
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setRescheduleOptionIds(newChecked);
  };

  const formatShiftTitle = (option) => {
    const dropShift = option.rescheduleactionSet.find(
      (e) => e.actionType === "DROP",
    );

    if (dropShift) {
      const start = dropShift.shift.start;
      const end = dropShift.shift.end;
      return (
        format(new Date(start), "dd MMM ") +
        format(new Date(start), "HH:mm") +
        "-" +
        format(new Date(end), "HH:mm")
      );
    } else {
      return "";
    }
  };

  const employeesToSwitchWith = () => {
    if (rescheduleOptions.length > 0) {
      const sortedOptions = rescheduleOptions
        .map((option) => {
          const employeeNotUser = option.rescheduleindividualSet.find(
            (individual) =>
              parseInt(individual.employee.id) !== parseInt(user.id),
          );
          return {
            ...employeeNotUser,
            optionId: option.id,
          };
        })
        .filter((option) => option.rescheduleactionSet);
      sortedOptions.sort((a, b) => b.benefit - a.benefit);

      let optionsToShow = sortedOptions;
      if (sortedOptions.length > 6 && !showAll) {
        optionsToShow = sortedOptions.slice(0, 6);
      }

      return optionsToShow.map((option, index) => (
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          spacing={1}
          key={index}
          onClick={handleCheckboxToggle(option)}
        >
          <Grid item xs={2}>
            <Checkbox
              checked={checked.indexOf(parseInt(option.optionId)) !== -1}
            />
          </Grid>
          <Grid item xs={10}>
            <Box ml={1}>
              <Typography>
                {option.employee.firstName + " " + option.employee.lastName}
                {option.benefit <= -1 && (
                  <StarRateIcon className={classes.icon} />
                )}
                {option.benefit < 1 && option.benefit > -1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
                {option.benefit >= 1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
              </Typography>
              {option.rescheduleactionSet.length > 0 && (
                <Typography variant="subtitle1">
                  {formatShiftTitle(option)}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      ));
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid
        container
        item
        spacing={1}
        style={{ height: 200, overflowY: "auto" }}
        alignContent="flex-start"
      >
        {employeesToSwitchWith()}
      </Grid>
      {rescheduleOptions.length > 6 && (
        <Grid item>
          <Button color="primary" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Show Less" : "Show More"}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default EmpSelectEmployeesandDates;
