import React, { useState, forwardRef } from "react";
import { Grid, Dialog, DialogContent, Button } from "@mui/material";
import TimeOffRequestForm from "./TimeOffRequestForm";

const AddCalendarEventForm = forwardRef((props, ref) => {
  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);

  return (
    <>
      <Grid item>
        <Button
          variant="outlined"
          color={"primary"}
          onClick={toggleTimeOffForm}
          data-testid="addCalendarEvent"
        >
          Add Calendar Event
        </Button>
      </Grid>
      <Dialog open={timeOffRequestForm} fullWidth maxWidth="md">
        <DialogContent data-testid="ShiftBasedtimeOffRequestForm">
          <TimeOffRequestForm
            closeDialog={toggleTimeOffForm}
            scheduleEndDate={props.scheduleEndDate}
            ref={ref}
            employees={props.employees}
            availabilityTypes={props.availabilityTypes}
            notifyDevelopers={props.notifyDevelopers}
            selectedOffice={props.selectedOffice}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default AddCalendarEventForm;
