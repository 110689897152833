import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Avatar,
  CssBaseline,
  Box,
  InputAdornment,
  IconButton,
  Paper,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpen from "@mui/icons-material/LockOpen";
import Copyright from "../general/Copyright";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@mui/icons-material/Error";
import { isLoggedInVar } from "../../cache";
import { useMutation, gql } from "@apollo/client";
import { userVar } from "../../cache";
import { NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { Amplify } from "aws-amplify";
import {
  fetchAuthSession,
  getCurrentUser,
  signIn,
  signOut,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { useHistory } from "react-router-dom";
import { CognitoUserVar } from "../../cache";
import LogRocket from "logrocket";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const awsconfig = {
  Auth: {
    Cognito: {
      userPoolClientId: window.REACT_APP_COGNITO_AUDIENCE,
      userPoolId: window.REACT_APP_COGNITO_USER_POOL,
      region: window.REACT_APP_COGNITO_AWS_REGION,
    },
    loginWith: {
      // Optional
      email: "true", // Optional
      username: "false",
    },
  },
};

Amplify.configure(awsconfig);

const GET_TOKEN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "160px",
    display: "flex",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "450px",
    margin: "auto",
    border: theme.shape.border,
    boxSizing: theme.shape.boxSizing,
    boxShadow: theme.shape.boxShadow,
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "40px",
    height: "40px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  inputWidth: {
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string().required("Required").email(),
  password: Yup.string().required("Required"),
});

const LoginForm = ({ error = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const environment = process.env.NODE_ENV;
  const baseURL = window.location.origin;
  let ssoURL = "";
  if (environment === "development" || baseURL.includes("amplify")) {
    ssoURL = "https://backendtest.balancescheduler.com/saml2/login/";
  } else {
    ssoURL = "https://backenddemo.balancescheduler.com/saml2/login/";
  }

  async function signin(email, password) {
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password,
      });

      if (isSignedIn === false) {
        history.push("/FirstTimeUser");
      } else if (isSignedIn === true) {
        const user = await getCurrentUser();
        let currentSession = await fetchAuthSession();
        CognitoUserVar(user);
        let appId;
        if (environment === "development") {
          appId = null;
        } else if (baseURL.includes("amplify")) {
          appId = "ix1pxb/test-dev-scheduler-ui";
        } else {
          appId = "ix1pxb/demo-scheduler-ui";
        }
        let testUserEmail = user.signInDetails.loginId.includes("@qa.comp");
        appId && testUserEmail === false && LogRocket.init(appId);
        sessionStorage.setItem("jwt", currentSession.tokens.idToken.toString());
        isLoggedInVar(true);
      }
    } catch (error) {
      console.log("error signing in", error);
      toastShow(" " + error);
    }
  }
  return (
    <main variant="body">
      <Grid className={classes.root}>
        <CssBaseline />
        <Grid className={classes.paper} component={Paper}>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // dispatch(attemptLogin(values));
              const { email, password } = values;
              signOut();
              setTimeout(() => {
                signin(email, password);
              }, 500);

              // getToken({
              //   variables: {
              //     email,
              //     password,
              //   },
              // });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={3}>
                  <Grid item align="center">
                    {/* {authError ?
                                            <Typography color="primary" variant="subtitle2"><b>{errMsg}</b></Typography>
                                            : null
                                        } */}
                    {error && (
                      <Typography color="primary" variant="subtitle2">
                        You are not authorized to access this application.
                      </Typography>
                    )}
                    <Avatar className={classes.avatar}>
                      <LockOpen />
                    </Avatar>
                    <Typography variant="h3">
                      Welcome to Balance Scheduler
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid>
                      <Typography variant="subtitle2">
                        Email Address*
                      </Typography>
                    </Grid>
                    <TextField
                      variant="outlined"
                      type="text"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Email"
                      className={classes.inputWidth}
                      error={errors.email != null}
                      data-testid="userlogin"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="large">
                              {errors.email != null ? (
                                <ErrorIcon color="primary" />
                              ) : null}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      color="primary"
                      variant="subtitle2"
                      data-testid="emailError"
                    >
                      {errors.email}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid container direction="row">
                      <Grid item xs={6} align="left">
                        <Typography variant="subtitle2">Password*</Typography>
                      </Grid>
                      <Grid item xs={6} align="right">
                        <Button
                          variant="text"
                          onClick={() => history.push("/ForgotPassword")}
                        >
                          Forgot password?
                        </Button>
                      </Grid>
                    </Grid>
                    <TextField
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Password"
                      className={classes.inputWidth}
                      error={!errors.password === null}
                      data-testid="userPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              size="large"
                            >
                              {errors.password != null ? (
                                <ErrorIcon color="primary" />
                              ) : showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      color="primary"
                      variant="subtitle2"
                      data-testid="passwordError"
                    >
                      {errors.password}
                    </Typography>
                  </Grid>

                  {/* <Grid item container justify='center' spacing={2}> */}
                  <Grid item align="center">
                    <Button
                      type="submit"
                      data-testid="loginSubmit"
                      variant="contained"
                      color="primary"
                    >
                      Sign In
                    </Button>
                  </Grid>
                  {/* <Grid item align="center">
                    <Button
                      component={Link}
                      href={ssoURL}
                      // target="blank"
                      color="primary"
                    >
                      Sign In with SSO
                    </Button>
                  </Grid> */}
                  {/* </Grid> */}

                  <Grid item>
                    <Box mb={3}>
                      <Copyright />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </main>
  );
};

export default LoginForm;
