import React, { useEffect, useState, forwardRef } from "react";
import ParticipantDelete from "./ParticipantDelete";
import EventParticipant from "./EventParticipant";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Box,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  GET_ALL_USERS_FOR_ADD_EDIT_SHIFT,
  ADD_AND_DROP_OPTIONS_FOR_SHIFT,
  GET_FLOAT_DETAILS,
  BATCH_CREATE_RESCHEDULE_OPTION,
  REQUEST_SINGLE_FLOAT,
  SAVE_SHIFT_CHANGES,
  REMOVE_SHIFT_ASSIGNMENT,
  CREATE_SHIFT_ASSIGNMENT,
  UPDATE_SHIFT_ASSIGNMENT_WORK_HOURS,
  SCHEDULER_INITIATE_OPTION,
} from "../../api/gqlQueries";
import { format, add, sub, startOfDay } from "date-fns";
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";
import { userVar } from "../../cache";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";
import {
  addParticipantsToShift,
  orderShifts,
} from "../../helpers/formatShiftEvents";
import SplitShiftTable from "./SplitShiftTable";
import { Formik, FieldArray, Form } from "formik";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import { MultiSelect } from "@syncfusion/ej2-react-dropdowns";
import { shiftEnter } from "@syncfusion/ej2-react-grids";
import { EventSeat } from "@mui/icons-material";
import { updateScheduleEvent } from "./UpdateEvents";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { DateTime } from "@syncfusion/ej2-react-charts";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
  commentBox: {
    width: "80%",
  },
}));

/*remove an employee from a shift
add an employee to a shift
*/

const MangEditShift = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    shiftEvent,
    closeDialog,
    refetch,
    notifyDevelopers,
    environment,
    getVariables,
    setScheduleData,
    scheduleData,
    setShiftToEdit,
    getSlacks,
    selectedOffice,
  } = props;
  const user = userVar();
  const officeId = user.office.id;
  const events = ref.current.eventsData.filter(
    (event) =>
      event.eventType === "SHIFT" || event.eventType === "SHIFTASSIGNMENTS",
  );
  // Get event ID of the event that needs to be edited
  const currentShiftId = shiftEvent.shiftId;
  // list of current employees assigned to this shift
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState(
    shiftEvent.participants,
  );
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);
  const [workHoursToUpdate, SetWorkHoursToUpdate] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [addOptions, setAddOptions] = useState([]);
  const [dropOptions, setDropOptions] = useState([]);
  const [acceptsFloats, setAcceptsFloats] = useState(false);
  const [showRequestFloats, setShowRequestFloats] = useState(false);
  const [floatComment, setFloatComment] = useState("");
  const [shiftRequirementSelected, SetShiftRequirementSelected] = useState();
  const [showShiftSplit, SetShowShiftSplit] = useState("");
  const [manualAddDropsForShifts, SetManualAddDropsForShifts] = useState([]);

  //get all employees of this office
  const { loading, error, data } = useQuery(GET_ALL_USERS_FOR_ADD_EDIT_SHIFT, {
    fetchPolicy: "network-only",
    variables: {
      shiftId: parseInt(shiftEvent.shiftId),
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [
    getEmpsForSelectedShift,
    {
      loading: getEmpsForSelectedShiftLoading,
      data: getEmpsForSelectedShiftData,
    },
  ] = useLazyQuery(GET_ALL_USERS_FOR_ADD_EDIT_SHIFT, {
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [updateShiftAssignmentWorkHours] = useMutation(
    UPDATE_SHIFT_ASSIGNMENT_WORK_HOURS,
    {
      onError(error) {
        console.log(error);
        toastShow(
          "We couldn't update shiftAssignment workhouts. Please try again",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_ALL_USERS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [saveShiftChanges] = useMutation(SAVE_SHIFT_CHANGES, {
    update(cache, { data: { saveShiftChanges } }) {
      const paginator = new DateTimePaginator(1);
      saveShiftChanges.slacks.forEach((element) => {
        const start = new Date(element.interval.start);
        const formatted = format(start, "MM/dd/yyyy");
        const dates = paginator.getQueryDateRanges(start, start);
        const variables = {
          issuesOnly: true,
          office: selectedOffice.id,
          pageEnd: dates[0][1].toISOString(),
          pageStart: dates[0][0].toISOString(),
        };
        const strVariables = JSON.stringify(variables);

        cache.modify({
          fields: {
            intervalSlacks: (existing, { storeFieldName }) => {
              /**
               * Modifier function to update the cached query `intervalSlacks`.
               *
               * Queries may be in the cache multiple times if they were called with
               * different arguments. This callback function will be called on each of
               * those cached fields so we check whether the variables we are interested
               * in is contained in the `storeFieldName` which is the full key that includes
               * the serialized variables. N.B. checking if a string includes a substring
               * means that the order of the variables and the type (int vs string) matter.
               * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
               * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
               *
               * @param {SlackNode[]} existing the array of object currently in the cache.
               * @param {String} storeFieldName the serialized full key of the field including variable arguments
               *
               * @returns {SlackNode[]} the new object appended to existing or the existing array.
               */
              if (storeFieldName.includes(strVariables)) {
                const newSlackRef = cache.writeFragment({
                  data: element,
                  fragment: gql`
                    fragment NewSlack on SlackNode {
                      id
                      required
                      slack
                      numAssigned
                      interval {
                        id
                        start
                        end
                        shifts {
                          id
                          start
                          end
                          procedure {
                            id
                            procedureRequirement {
                              id
                              name
                            }
                          }
                          office {
                            id
                            name
                          }
                        }
                      }
                      skill {
                        id
                        name
                      }
                    }
                  `,
                });
                if (!ref.current.slackEvents[formatted]) {
                  ref.current.slackEvents[formatted] = {};
                }
                ref.current.slackEvents[formatted][element.id] = element;
                return [...existing, newSlackRef];
              } else {
                return existing;
              }
            },
          },
        });
      });
    },
    onCompleted(d) {
      updateScheduleEvent(
        ref.current,
        d.saveShiftChanges.shift,
        shiftEvent,
        selectedOffice.name,
      );
      ref.current.refreshTemplates("dateHeaderTemplate");
    },
  });

  // show/hide float request form
  const toggleShowRequestFloats = () =>
    setShowRequestFloats(!showRequestFloats);

  // query for getting add employee and drop employee options for shift
  const [getRescheduleOptions] = useLazyQuery(ADD_AND_DROP_OPTIONS_FOR_SHIFT, {
    onCompleted(data) {
      const addOptions =
        data.rescheduleAdds.length > 0
          ? data.rescheduleAdds
              .filter((option) => option.status === "OPTION")
              .map((add) => {
                const employeeIdsAndBenefits = add.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: add.id,
                    };
                  },
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];

      const dropOptions =
        data.rescheduleDrops.length > 0
          ? data.rescheduleDrops
              .filter((option) => option.status === "OPTION")
              .map((drop) => {
                const employeeIdsAndBenefits = drop.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: drop.id,
                    };
                  },
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];
      setAddOptions(addOptions);
      setDropOptions(dropOptions);
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_AND_DROP_OPTIONS_FOR_SHIFT Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // get reschedule add and drop option for current shift ID
  useEffect(() => {
    getRescheduleOptions({
      variables: {
        shifts: [parseInt(currentShiftId)],
      },
    });
  }, []);

  // get office float settings
  const { data: officeData } = useQuery(GET_FLOAT_DETAILS, {
    onError(error) {
      console.log(error);
      toastShow("Error Edit Shift. Graphql " + error.message, "Error");
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_FLOAT_DETAILS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (officeData) {
      const currentOffice = officeData.offices.find(
        (office) => office.id === officeId,
      );
      setAcceptsFloats(currentOffice.floatStatus === false);
    }
  }, [officeData]);

  const [batchCreateOptions, { data: batchCreateOptionsData }] = useMutation(
    BATCH_CREATE_RESCHEDULE_OPTION,
    {
      onCompleted(d) {
        console.log(d);
        if (
          window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === true ||
          window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === "true"
        ) {
          d.batchCreateRescheduleOption.options.length > 0 &&
            d.batchCreateRescheduleOption.options.map((e) => {
              schedulerInitiateOption({
                variables: {
                  option: parseInt(e.id),
                },
              });
            });
        }

        refetch();
      },
    },
    {
      onError(error) {
        console.log(error);
        toastShow("Error Edit Shift. Graphql " + error.message, "Error");
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [schedulerInitiateOption, { data: schedulerInitiateOptionData }] =
    useMutation(SCHEDULER_INITIATE_OPTION, {
      onCompleted(d) {
        console.log(d);
        refetch();
      },
      onError(error) {
        console.log(error);
        toastShow("Error Edit Shift. Graphql " + error.message, "Error");
        notifyDevelopers({
          variables: {
            message:
              "Error on SCHEDULER_INITIATE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  const [requestFloat] = useMutation(REQUEST_SINGLE_FLOAT, {
    onCompleted(data) {
      toastShow("Float Request Successful", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow("Error Edit Shift. Graphql " + error.message, "Error");
      notifyDevelopers({
        variables: {
          message:
            "Error on REQUEST_SINGLE_FLOAT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const renderParticipants = (newAddition) => {
    let participants;
    if (newAddition) {
      participants = employeesToAdd;
    } else {
      participants = shiftEvent.participants;
    }
    participants &&
      participants.length > 0 &&
      participants.sort(function (a, b) {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.LastName) {
          return 1;
        }
        return 0;
      });
    return (
      participants &&
      participants.map((participant, index) => {
        const option =
          participant && newAddition
            ? addOptions.find(
                (option) => option.employeeId === participant.employeeId,
              )
            : dropOptions.find(
                (option) => option.employeeId === participant.employeeId,
              );
        return (
          <Grid
            item
            container
            key={index}
            alignItems="flex-start"
            spacing={1}
            style={{ margin: 5, marginRight: 25 }}
          >
            <Grid item xs={2}>
              {option && (
                <>
                  {option.benefit <= -1 && (
                    <StarRateIcon className={classes.icon} />
                  )}
                  {option.benefit < 1 && option.benefit > -1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                  {option.benefit >= 1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                </>
              )}
            </Grid>
            {newAddition ? (
              <ParticipantDelete
                participant={participant}
                newAddition={true}
                employeesToAdd={employeesToAdd}
                setEmployeesToAdd={setEmployeesToAdd}
                currentShiftEmployees={currentShiftEmployees}
                setCurrentShiftEmployees={setCurrentShiftEmployees}
                officeId={officeId}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
                shiftEvent={shiftEvent}
              />
            ) : (
              <ParticipantDelete
                participant={participant}
                employeesToDelete={employeesToDelete}
                setEmployeesToDelete={setEmployeesToDelete}
                officeId={officeId}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
                shiftEvent={shiftEvent}
                workHoursToUpdate={workHoursToUpdate}
                SetWorkHoursToUpdate={SetWorkHoursToUpdate}
              />
            )}
          </Grid>
        );
      })
    );
  };

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      let adds = [];
      let others = [];
      employees.forEach((employee) => {
        const add = addOptions.find(
          (option) => option.employeeId === employee.id,
        );
        if (add) {
          adds = [...adds, { ...add, ...employee }];
        } else {
          others = [...others, { ...employee }];
        }
      });
      adds.sort((a, b) => b.benefit - a.benefit);

      return [...adds, ...others].filter(Boolean);
    } else {
      return "No Eligible Employees";
    }
  };

  const handleAddEmployee = (e) => {
    setSelectedEmployeeToAdd(e.target.value);
  };

  const handleSubmit = (e) => {
    if (selectedEmployeeToAdd) {
      setErrorMessage("Click Add button to save selected employee to shift");
      return;
    }
    console.log(employeesToAdd);
    console.log(employeesToDelete);
    saveShiftChanges({
      variables: {
        shift: shiftEvent.shiftId,
        adds: employeesToAdd.map((e) => e.id),
        drops: employeesToDelete.map((e) => e.employeeId),
      },
    });
    if (new Date(shiftEvent.start) < new Date()) {
      shiftEvent.participants.length > 0 &&
        shiftEvent.participants.map((e) =>
          updateShiftAssignmentWorkHours({
            variables: {
              id: parseInt(e.id),
              workHours: parseFloat(e.workHours) * 1.0,
            },
          }),
        );
    }
    toastShow("Manager Edit Shift Successful", "Success");
    closeDialog();
  };

  // mutation to request for float employees to fill a shift
  const handleSendFloatRequest = () => {
    requestFloat({
      variables: {
        shift: currentShiftId,
        comments: floatComment,
      },
    });
  };

  // submit button is enabled only if there are employees to add or employees to be deleted from a shift
  const eligibleToSave = Boolean(
    true,
    /// employeesToAdd.length > 0 || employeesToDelete.length > 0 || workHoursToUpdate.length > 0
  );

  let listOfChildShifts =
    events.length > 0 &&
    events.filter(
      (e) =>
        (e.parentShiftId != null &&
          parseInt(e.parentShiftId) === parseInt(shiftEvent.shiftId) &&
          e.participants) ||
        (e.rootShiftId != null &&
          e.rootShiftId === shiftEvent.shiftId &&
          e.participants),
    );
  const [childSplitShifts, SetChildSplitShifts] = useState(
    listOfChildShifts && listOfChildShifts.length > 0
      ? [shiftEvent, ...listOfChildShifts]
      : [shiftEvent],
  );

  let eligibleEmployees =
    data && data.manualAddEmployees
      ? data.manualAddEmployees
      : getEmpsForSelectedShiftData &&
        getEmpsForSelectedShiftData.manualAddEmployees.length > 0 &&
        getEmpsForSelectedShiftData.manualAddEmployees;

  const temporaryAdd = () => {
    console.log(selectedEmployeeToAdd);
    if (selectedEmployeeToAdd) {
      const employeeToAdd =
        eligibleEmployees &&
        eligibleEmployees.length > 0 &&
        eligibleEmployees.find((user) => user.id === selectedEmployeeToAdd.id);
      setEmployeesToAdd([...employeesToAdd, employeeToAdd]);
      currentShiftEmployees && currentShiftEmployees.length > 0
        ? setCurrentShiftEmployees([...currentShiftEmployees, employeeToAdd])
        : setCurrentShiftEmployees([employeeToAdd]);
      setSelectedEmployeeToAdd("");
      setErrorMessage("");
    } else {
      setErrorMessage("Must select employee to add");
    }
  };

  const handleSplitShiftClick = (e) => {
    SetShowShiftSplit(true);
  };

  if (showShiftSplit === true) {
    return (
      <SplitShiftTable
        shiftEvent={shiftEvent}
        splitShifts={childSplitShifts}
        SetShowShiftSplit={SetShowShiftSplit}
        notifyDevelopers={notifyDevelopers}
        events={events}
        closeDialog={closeDialog}
        environment={environment}
        selectedOffice={selectedOffice}
        ref={ref}
      />
    );
  } else {
    return (
      <div data-testid="ShiftBasedEditShiftForm">
        {showRequestFloats ? (
          <Grid item container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignContent="flex-start"
            >
              <Grid item>
                <Typography variant="h3">
                  Request Floats for {shiftEvent.shiftId}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  color="secondary"
                  onClick={closeDialog}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {format(new Date(shiftEvent.start), "dd MMM yyyy")}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Typography variant="h6" style={{ marginBottom: 8 }}>
                Comment for Float Manager:
              </Typography>
              <TextField
                variant="outlined"
                id="float-comment"
                value={floatComment}
                onChange={(e) => setFloatComment(e.target.value)}
                multiline
                className={classes.commentBox}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item container direction="column" spacing={2}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignContent="flex-start"
              spacing={2}
            >
              <Grid item xs={6}>
                <Typography variant="h3">
                  Edit Shift{" "}
                  {format(shiftEvent.start, "HH:mm") +
                    " to " +
                    format(shiftEvent.end, "HH:mm")}{" "}
                </Typography>
                <Grid style={{ marginTop: 15, marginBottom: 15 }}>
                  <Typography variant="h4">
                    {format(shiftEvent.start, "dd-MMM-yyyy")}{" "}
                  </Typography>
                </Grid>

                {shiftEvent.parentShiftId != null && (
                  // TODO: use ref.current.getCurrentViewEvents() rather than events
                  <Button
                    variant="h5"
                    onClick={() => {
                      let ev = events.find(
                        (e) =>
                          shiftEvent.parentShiftId != null &&
                          e.shiftId === shiftEvent.parentShiftId,
                      );

                      let listOfChildShifts = events.filter(
                        (e) =>
                          (e.parentShiftId != null &&
                            parseInt(e.parentShiftId) ===
                              parseInt(ev.shiftId) &&
                            e.participants) ||
                          (e.rootShiftId != null &&
                            e.rootShiftId === shiftEvent.shiftId &&
                            e.participants),
                      );
                      SetChildSplitShifts([ev, ...listOfChildShifts]);
                      setShiftToEdit(ev);
                    }}
                  >
                    Back to Parent Shift{" "}
                  </Button>
                )}
                <Grid style={{ marginTop: 20 }}>
                  <List>
                    {childSplitShifts.map((shift) => (
                      <ListItemButton
                        key={shift.shiftId}
                        onClick={() => {
                          setShiftToEdit(shift);
                          let selectedShiftChildShifts = events.filter(
                            (e) =>
                              (e.parentShiftId != null &&
                                parseInt(e.parentShiftId) ===
                                  parseInt(shift.shiftId) &&
                                e.participants) ||
                              (e.rootShiftId != null &&
                                e.rootShiftId === shift.shiftId &&
                                e.participants),
                          );
                          SetChildSplitShifts([
                            shift,
                            ...selectedShiftChildShifts,
                          ]);
                          getEmpsForSelectedShift({
                            variables: {
                              shiftId: parseInt(shift.shiftId),
                            },
                          });
                          getRescheduleOptions({
                            variables: {
                              shifts: [parseInt(shift.shiftId)],
                            },
                          });
                        }}
                      >
                        {format(new Date(shift.start), "dd MMM yyyy HH:mm") +
                          " - " +
                          format(new Date(shift.end), "dd MMM yyyy HH:mm")}
                      </ListItemButton>
                    ))}
                  </List>{" "}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={handleSplitShiftClick}
                  data-testid="splitShiftButton"
                >
                  Split
                </Button>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={2}
                data-testid="closeShiftBasedEditShiftForm"
              >
                <Grid item>
                  <IconButton
                    color="secondary"
                    onClick={closeDialog}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h5">Employees Scheduled</Typography>
              </Grid>
              <Grid item container justifyContent="space-between" wrap="wrap">
                {renderParticipants(false)}
                {renderParticipants(true)}
              </Grid>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <InputLabel id="select-employee-1-label">
                    <Typography variant="h6">Add Employee:</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    multiple={false}
                    name="employeeSearchBox"
                    value={
                      selectedEmployeeToAdd != "" ? selectedEmployeeToAdd : null
                    }
                    size="small"
                    disableClearable={true}
                    onChange={(event, newValue) => {
                      setSelectedEmployeeToAdd(newValue);
                    }}
                    loading={
                      loading || getEmpsForSelectedShiftLoading ? true : false
                    }
                    options={
                      data && data.manualAddEmployees.length > 0
                        ? renderEmployeeOptions(data.manualAddEmployees)
                        : getEmpsForSelectedShiftData &&
                            getEmpsForSelectedShiftData.manualAddEmployees
                              .length > 0
                          ? renderEmployeeOptions(
                              getEmpsForSelectedShiftData.manualAddEmployees,
                            )
                          : []
                    }
                    placeholder="Search Employee"
                    getOptionLabel={(option) =>
                      option.lastName + " " + option.firstName
                    }
                    renderOption={(p, option, { selected }) => (
                      <li {...p}>
                        {option.benefit <= -1 && (
                          <StarRateIcon className={classes.icon} />
                        )}
                        {option.benefit < 1 && option.benefit > -1 && (
                          <>
                            <StarRateIcon className={classes.icon} />
                            <StarRateIcon className={classes.icon} />
                          </>
                        )}
                        {option.benefit >= 1 && (
                          <>
                            <StarRateIcon className={classes.icon} />
                            <StarRateIcon className={classes.icon} />
                            <StarRateIcon className={classes.icon} />
                          </>
                        )}
                        {option.lastName + ", " + option.firstName + ": "}
                        {option.skills &&
                          option.skills.length > 0 &&
                          option.skills.map((e) => e.name).join(",")}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search Employee"
                        autoFocus
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={temporaryAdd}
                    disabled={!selectedEmployeeToAdd}
                    data-testid="addSelectedEmployeeButton"
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    onClick={() => setSelectedEmployeeToAdd("")}
                    disabled={!selectedEmployeeToAdd}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              {errorMessage && (
                <Grid item>
                  <Typography className={classes.error}>
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ marginTop: 15 }}
        >
          {acceptsFloats ? (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleShowRequestFloats}
              >
                {showRequestFloats ? "Cancel" : "Request Float Staff"}
              </Button>
            </Grid>
          ) : (
            <Grid item></Grid>
          )}
          {showRequestFloats ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendFloatRequest}
              >
                Send Request
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!eligibleToSave}
                data-testid="handleShiftBasedEditShiftSave"
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
});

export default MangEditShift;
