import React, { useEffect, useState } from "react";
import LoginForm from "../userAuthentication/LoginForm";
import ForgotPassword from "../userAuthentication/ForgotPassword";
import SAMLLogin from "../userAuthentication/SAMLLogin";
import {
  Container,
  Toolbar,
  CssBaseline,
  Box,
  Link,
  AppBar,
  Alert,
  Typography,
  Snackbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Switch, Route, useHistory } from "react-router-dom";
import { PUBLICLOCATION } from "../../api/gqlQueries";
import { useApolloClient, useQuery } from "@apollo/client";
import { appsettingsVar } from "../../cache";
import FirstTimeUser from "../userAuthentication/FirstTimeUserPassword";
import VerifyCode from "../userAuthentication/verifyCode";
import { handleLogout } from "./LogOutDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

function UnauthenticatedApp({ errored = false }) {
  const history = useHistory();
  const classes = useStyles();
  const client = useApolloClient();
  const locationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.error(error);
    },
  });
  const [logourl, setlogourl] = useState("");
  const [logoErr, setLogoErr] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const appSettings = appsettingsVar();

  const currentLogo =
    !locationSettings.loading &&
    locationSettings.data &&
    locationSettings.data.locationPublicSettings[0].logo;
  const baseURL = window.location.origin;
  const environment = process.env.NODE_ENV;
  appSettings.logo = currentLogo;

  let url = document.URL;
  url = url.split("//")[1];
  let urlLink = url.includes("/") && url.split("/")[1];
  if (
    urlLink &&
    urlLink.length > 0 &&
    urlLink != "ForgotPassword" &&
    urlLink != "FirstTimeUser" &&
    urlLink != "VerifyCode"
  ) {
    history.push("/");
  }

  useEffect(() => {
    if (errored) {
      handleLogout(client);

      //   show toast message to user
      setShowToast(true);
    }
  }, []);

  useEffect(() => {
    const currentLogo =
      !locationSettings.loading &&
      locationSettings.data &&
      locationSettings.data.locationPublicSettings[0].logo;
    const baseURL = window.location.origin;
    const environment = process.env.NODE_ENV;
    appSettings.logo = currentLogo;
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [!locationSettings.loading]);

  useEffect(() => {
    const currentLogo =
      !locationSettings.loading &&
      locationSettings.data &&
      locationSettings.data.locationPublicSettings[0].logo;
    const baseURL = window.location.origin;
    const environment = process.env.NODE_ENV;
    appSettings.logo = currentLogo;
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [appSettings.logo]);

  if (locationSettings.loading) {
    return <div>loading...</div>;
  } else {
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer && classes.root}>
          <Container>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar)}>
              <Toolbar className={classes.toolbar}>
                <Box component={Link} to="/">
                  <img
                    src={window.LOGO}
                    height={75}
                    alt={window.LOGO.split(".")[0]
                      .split("-")
                      .join(" ")
                      .concat(" logo")}
                  />
                </Box>
              </Toolbar>
            </AppBar>
            <Box>
              <Switch>
                <Route path="/" exact component={LoginForm} />
                <Route path="/FirstTimeUser" exact component={FirstTimeUser} />
                <Route
                  path="/ForgotPassword"
                  exact
                  component={ForgotPassword}
                />
                <Route path="/VerifyCode" exact component={VerifyCode} />
                <Route path="/saml2/login" exact component={SAMLLogin} />
                <Route path="/saml2/unauthorized">
                  {(props) => <LoginForm {...props} error={true} />}
                </Route>
              </Switch>
            </Box>
          </Container>
        </div>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="error"
          >
            <Typography>An error occurred, please try again</Typography>
          </Alert>
        </Snackbar>
      </main>
    );
  }
}

export default UnauthenticatedApp;
