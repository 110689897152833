import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  Checkbox,
  Button,
  Grid,
  Icon,
  Box,
  Popover,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_NOTIFICATIONS,
  // EMPLOYEE_ACCEPT_SHIFT_SWITCH,
  // MANAGER_APPROVE_SHIFT_SWITCH,
  // EMPLOYEE_DENY_SHIFT_SWITCH,
  // MANAGER_DENY_SHIFT_SWITCH,
  BATCH_UPDATE_NOTIFICATION,
  // UPDATE_NOTIFICATION,
  // MANAGER_APPROVE_EMPLOYEE_AVAILABILITY,
  // MANAGER_DENY_EMPLOYEE_AVAILABILITY
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import NotificationItem from "./NotificationItem";
import NotificationsFilterMenu from "./NotificationsFilterMenu";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";

let toastObj;
function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  topSpacing: {
    marginTop: 20,
  },
  button: {
    width: 90,
  },
  input: {
    width: 384,
  },
  read: {
    background: "rgba(134, 134, 137, 0.15)",
  },
}));

const Notifications = () => {
  const classes = useStyles();

  const user = userVar();

  const { loading, error, data, refetch } = useQuery(GET_ALL_NOTIFICATIONS, {
    variables: { employeeId: parseInt(user.id) },
    fetchPolicy: "cache-and-network",
    pollInterval: 15000,
    onCompleted(data) {
      setFilteredNotifications(data.notifications);
    },
    onError(err) {
      if (err.message.includes("Signature has expired")) {
        console.log(err.message);
        handleLogout();
      }
    },
  });

  const [batchDeleteNotification] = useMutation(BATCH_UPDATE_NOTIFICATION, {
    onCompleted(data) {
      console.log(data);
      const returnedIds = data.batchUpdateNotification.notifications.map(
        (notification) => notification.id,
      );
      // const newChecked = checked.filter(id => id !== returnedId);
      const newFiltered = filteredNotifications.filter(
        (notification) => !returnedIds.includes(notification.id),
      );
      setFilteredNotifications(newFiltered);
      setChecked([]);
      toastShow("Notification Deleted", "Success");
    },
    onError(error) {
      console.log(error);
      toastShow("Error Delete Notification", "Error");
    },
  });

  const [batchUpdateNotification] = useMutation(BATCH_UPDATE_NOTIFICATION, {
    onCompleted(data) {
      console.log(data);
      setChecked([]);
      refetch();
    },
    onError(error) {
      console.log(error);
      toastShow("Error Update Notification", "Error");
    },
  });

  const [batchUpdateNotificationAfterNavigation] = useMutation(
    BATCH_UPDATE_NOTIFICATION,
    {
      onCompleted(data) {
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    },
  );

  const [checked, setChecked] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [filters, setFilters] = useState([
    "All",
    "Shift Switch",
    "Time Off",
    "General",
    "Schedule",
  ]);

  useEffect(() => {
    return function markAsRead() {
      const generalTypes = ["GEN", "SCR", "SCG", "SCP"];
      if (data && data.notifications) {
        const unreadGeneralNotifications = data.notifications.filter(
          (notification) => {
            return (
              generalTypes.includes(notification.type) &&
              notification.read === false
            );
          },
        );
        const input = unreadGeneralNotifications.map((notification) => {
          return {
            id: notification.id,
            read: true,
          };
        });
        batchUpdateNotificationAfterNavigation({ variables: { input: input } });
      }
    };
  }, []);

  const handleCheckboxToggle = (id) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    if (checked.length === filteredNotifications.length) {
      setChecked([]);
    } else {
      const filteredNotificationIds = filteredNotifications.map(
        (notification) => notification.id,
      );
      setChecked(filteredNotificationIds);
    }
  };

  const handleDeleteNotifications = () => {
    const input = checked.map((id) => {
      return {
        id: id,
        userDeleted: true,
      };
    });
    batchDeleteNotification({ variables: { input: input } });
  };

  const markAsRead = () => {
    const input = checked.map((id) => {
      return {
        id: id,
        read: true,
      };
    });
    batchUpdateNotification({ variables: { input: input } });
  };

  const markAsUnread = () => {
    const input = checked.map((id) => {
      return {
        id: id,
        read: false,
      };
    });
    batchUpdateNotification({ variables: { input: input } });
  };

  if (loading) {
    return <CircularProgress />;
  } else if (error) {
    console.log(error);
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const notifications = [...data.notifications];

    const renderedNotifications = (notificationsToView) => {
      let notifications = [...notificationsToView];
      notifications.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });

      const readNotifications = notifications.filter(
        (notification) => notification.read,
      );
      const unreadNotifications = notifications.filter(
        (notification) => !notification.read,
      );
      notifications = [...unreadNotifications, ...readNotifications];

      return notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          handleCheckboxToggle={handleCheckboxToggle}
          checked={checked}
          refetch={refetch}
        />
      ));
    };

    const handleFilterClick = (filterValue) => {
      let newFilters = [...filters];
      const currentIndex = newFilters.indexOf(filterValue);
      let indexOfAll = newFilters.indexOf("All");
      if (currentIndex === -1 && filterValue === "All") {
        newFilters = ["All", "General", "Shift Switch", "Time Off", "Schedule"];
      } else if (currentIndex === -1) {
        newFilters.push(filterValue);
      } else if (currentIndex !== -1 && filterValue === "All") {
        newFilters = [];
      } else if (currentIndex !== -1 && indexOfAll !== -1) {
        newFilters.splice(currentIndex, 1);
        indexOfAll = newFilters.indexOf("All");
        newFilters.splice(indexOfAll, 1);
      } else {
        newFilters.splice(currentIndex, 1);
      }
      setFilters(newFilters);

      const newNotifications = [];
      if (newFilters.includes("Shift Switch") || newFilters.includes("All")) {
        const ssNotifications = notifications.filter((notification) => {
          const ssTypes = ["SSR", "SSA", "SSD", "SSY", "SSN", "SSM"];
          return ssTypes.includes(notification.type);
        });
        newNotifications.push(ssNotifications);
      }
      if (newFilters.includes("Time Off") || newFilters.includes("All")) {
        const toNotifications = notifications.filter((notification) => {
          const toTypes = ["TOR", "TOD", "TOA"];
          return toTypes.includes(notification.type);
        });
        newNotifications.push(toNotifications);
      }
      if (newFilters.includes("Schedule") || newFilters.includes("All")) {
        const toNotifications = notifications.filter((notification) => {
          const scheduleTypes = ["SCG", "SCR", "SCP"];
          return scheduleTypes.includes(notification.type);
        });
        newNotifications.push(toNotifications);
      }
      if (newFilters.includes("General") || newFilters.includes("All")) {
        const generalNotifications = notifications.filter((notification) => {
          const generalTypes = ["GEN", "ECI", "MCO", "MCA"];
          return generalTypes.includes(notification.type);
        });
        newNotifications.push(generalNotifications);
      }
      setFilteredNotifications(newNotifications.flat());
    };

    const handleResetFilters = (event) => {
      event.stopPropagation();
      setFilters(["All", "Shift Switch", "Time Off", "General"]);
      setFilteredNotifications(notifications);
    };

    return (
      <>
        <Typography variant="h3" className={classes.headerSpacing}>
          Notifications
        </Typography>
        <Grid
          container
          component={Box}
          wrap="nowrap"
          justifyContent="space-between"
          border={1}
          borderColor="primary.main"
          borderLeft={0}
          borderRight={0}
          borderTop={0}
          data-testid="notificationsList"
        >
          <Grid
            item
            container
            spacing={1}
            xs={8}
            md={6}
            alignItems="center"
            style={{ paddingLeft: 16 }}
          >
            <Grid item>
              <Checkbox
                checked={
                  filteredNotifications.length !== 0 &&
                  checked.length === filteredNotifications.length
                }
                onClick={handleSelectAll}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {checked.length} Selected
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">|</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {filteredNotifications.length} Total
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={4}
            md={6}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                color="secondary"
                onClick={markAsRead}
                disabled={filteredNotifications.length === 0}
              >
                <DraftsIcon style={{ marginRight: 5 }} />
                Mark as Read
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                onClick={markAsUnread}
                disabled={filteredNotifications.length === 0}
              >
                <MailIcon style={{ marginRight: 5 }} />
                Mark as Unread
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                onClick={handleDeleteNotifications}
                disabled={filteredNotifications.length === 0}
              >
                <Icon style={{ marginRight: 5 }}>delete</Icon>
                Delete
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Icon style={{ marginRight: 5 }}>tune</Icon>
                Filter
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <Paper style={{ padding: 10, overflow: "hidden" }}>
            <NotificationsFilterMenu
              filters={filters}
              handleFilterClick={handleFilterClick}
              handleResetFilters={handleResetFilters}
            />
          </Paper>
        </Popover>
        <List style={{ marginTop: 10 }}>
          {filteredNotifications.length !== 0 ? (
            renderedNotifications(filteredNotifications)
          ) : (
            <Typography variant="h6">No notifications to display</Typography>
          )}
        </List>
      </>
    );
  }
};

export default Notifications;
