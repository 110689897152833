import React, { useState, forwardRef } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { add, format } from "date-fns";
import {
  CREATE_EMPLOYEE_AVAILABILITY,
  MANAGER_CREATE_EMPLOYEE_AVAILABILITY,
  BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY,
  NOTIFY_DEVELOPERS,
  EMPLOYEE_AVAILABILITY_FRAGMENT,
  SLACK_FRAGMENT,
} from "../../api/gqlQueries";
import { useMutation, gql } from "@apollo/client";
import { selectedDateVar, userVar } from "../../cache";
import Roles from "../../Roles/roles";
import EmployeeSearchBox from "../general/EmployeeSearchBox";
import {
  updateUnavailabilityEvent,
  updateScheduleFromOption,
} from "./UpdateEvents";
import { toIsoDate, getLocalIsoOffset } from "../../helpers/formatTime";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { getSlackTransformer } from "./Transformer";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  employeeInput: {
    width: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wideInput: {
    width: 575,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: 50,
  },
  timeField: {
    width: 120,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

/* Add time off on a given date
   start date and end date can span over a few days.
   user can select a part of the day or user can set the event to an all day event.
   user can delete their time off requests
*/

const TimeOffRequestForm = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    closeDialog,
    scheduleEndDate,
    invalidDates,
    employees,
    refetch,
    environment,
    SetAllDateTimeOffs,
    timeOffRequestsRefetch,
    availabilityTypes,
    selectedOffice,
  } = props;

  const user = userVar();
  const date = selectedDateVar();

  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  const [employee, setEmployee] = useState(
    managerAccess ? null : employees.find((e) => e.id === user.id),
  );
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [isAllDay, setIsAllDay] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [workHours, setWorkHours] = useState(0);
  const [type, setType] = useState(null);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [endError, setEndError] = useState("");
  const [startError, setStartError] = useState("");
  const minimumDate = managerAccess
    ? new Date()
    : scheduleEndDate
      ? add(new Date(scheduleEndDate), { days: 1 })
      : new Date();

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const [managerCreateTimeOff] = useMutation(
    MANAGER_CREATE_EMPLOYEE_AVAILABILITY,
    {
      update(cache, { data: { managerInitiateTimeOff } }) {
        const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
        const element = managerInitiateTimeOff.timeOffRequest;
        const start = new Date(
          element.firstday + "T00:00:00" + getLocalIsoOffset(),
        );
        const end = new Date(
          element.lastday + "T23:00:00" + getLocalIsoOffset(),
        );
        const dates = paginator.getQueryDateRanges(start, end);
        dates.forEach((dateArray) => {
          const variables = {
            endDate: toIsoDate(dateArray[1]),
            officeId: selectedOffice.id,
            startDate: toIsoDate(dateArray[0]),
          };
          const strVariables = JSON.stringify(variables);
          cache.modify({
            fields: {
              availability: (existing, { storeFieldName }) => {
                if (storeFieldName.includes(strVariables)) {
                  const newTimeOffRef = cache.writeFragment({
                    data: element,
                    fragment: EMPLOYEE_AVAILABILITY_FRAGMENT,
                  });
                  return [...existing, newTimeOffRef];
                } else {
                  return existing;
                }
              },
            },
          });
        });

        const newSlacks = getSlackTransformer(
          -1,
          -1,
        )(managerInitiateTimeOff.slacks);

        ref.current.slackEvents = {
          ...ref.current.slackEvents,
          ...newSlacks,
        };

        managerInitiateTimeOff.slacks.forEach((element) => {
          const start = new Date(element.interval.start);
          const formatted = format(start, "MM/dd/yyyy");
          const dates = paginator.getQueryDateRanges(start, start);
          const variables = {
            issuesOnly: true,
            office: selectedOffice.id,
            pageEnd: dates[0][1].toISOString(),
            pageStart: dates[0][0].toISOString(),
          };
          const strVariables = JSON.stringify(variables);
          cache.modify({
            fields: {
              intervalSlacks: (existing, { storeFieldName }) => {
                /**
                 * Modifier function to update the cached query `intervalSlacks`.
                 *
                 * Queries may be in the cache multiple times if they were called with
                 * different arguments. This callback function will be called on each of
                 * those cached fields so we check whether the variables we are interested
                 * in is contained in the `storeFieldName` which is the full key that includes
                 * the serialized variables. N.B. checking if a string includes a substring
                 * means that the order of the variables and the type (int vs string) matter.
                 * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
                 * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
                 *
                 * @param {SlackNode[]} existing the array of object currently in the cache.
                 * @param {String} storeFieldName the serialized full key of the field including variable arguments
                 *
                 * @returns {SlackNode[]} the new object appended to existing or the existing array.
                 */
                if (storeFieldName.includes(strVariables)) {
                  const newSlackRef = cache.writeFragment({
                    data: element,
                    fragment: SLACK_FRAGMENT,
                  });
                  return [...existing, newSlackRef];
                } else {
                  return existing;
                }
              },
            },
          });
        });
      },
      onCompleted(data) {
        if (ref) {
          updateUnavailabilityEvent(
            ref.current,
            data.managerInitiateTimeOff.timeOffRequest,
            null,
            selectedOffice.name,
          );
          updateScheduleFromOption(
            ref.current,
            data.managerInitiateTimeOff.impactedShifts,
            selectedOffice.name,
          );
          ref.current.refreshTemplates("dateHeaderTemplate");
        }

        toastShow("New Time Off Request created", "Success");
        closeDialog();
      },
      onError(error) {
        console.log(error);
        toastShow(
          "Unable to create new time off request. Please check dates and try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on MANAGER_CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  //TO DO, update cache for batch mutation
  const [batchManagerCreateTimeOff] = useMutation(
    BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY,
    {
      onCompleted(data) {
        if (ref) {
          updateUnavailabilityEvent(
            ref.current,
            data.batchManagerInitiateTimeOff.timeOffRequests,
            null,
            selectedOffice.name,
          );
        }
        // TODO: data will be an array of requests as opposed to an object
        toastShow("New Time Off Request created", "Success");
        closeDialog();
      },
      onError(error) {
        console.log(error);
        toastShow(
          "Unable to create new time off request. Please check dates and try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    },
  );

  const [createTimeOff] = useMutation(CREATE_EMPLOYEE_AVAILABILITY, {
    update(cache, { data: { createTimeOffRequestWithNotifications } }) {
      const paginator = new DateTimePaginator(1); // TODO: using in multiple spots need the same constructor
      const element = createTimeOffRequestWithNotifications.timeOffRequest;
      const start = new Date(
        element.firstday + "T00:00:00" + getLocalIsoOffset(),
      );
      const end = new Date(element.lastday + "T23:00:00" + getLocalIsoOffset());
      const dates = paginator.getQueryDateRanges(start, end);
      dates.forEach((dateArray) => {
        const variables = {
          endDate: toIsoDate(dateArray[1]),
          officeId: selectedOffice.id,
          startDate: toIsoDate(dateArray[0]),
        };
        const strVariables = JSON.stringify(variables);
        cache.modify({
          fields: {
            availability: (existing, { storeFieldName }) => {
              if (storeFieldName.includes(strVariables)) {
                const newTimeOffRef = cache.writeFragment({
                  data: element,
                  fragment: EMPLOYEE_AVAILABILITY_FRAGMENT,
                });
                return [...existing, newTimeOffRef];
              } else {
                return existing;
              }
            },
          },
        });
      });
    },
    onCompleted(data) {
      if (ref) {
        updateUnavailabilityEvent(
          ref.current,
          data.createTimeOffRequestWithNotifications.timeOffRequest,
          null,
          selectedOffice.name,
        );
      }

      toastShow("New Time Off Request created", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to create new time off request. Please check dates and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    let newEvent = {
      office: parseInt(user.office.id),
      type: type,
      workHours: parseInt(workHours),
      firstday: format(startDate, "yyyy-MM-dd"),
      lastday: format(endDate, "yyyy-MM-dd"),
      comment: comment,
    };

    if (startTime && endTime) {
      newEvent.startTime = `${startTime}:00`;
      newEvent.endTime = `${endTime}:00`;
    }

    if (managerAccess) {
      if (employee === "All") {
        let timeOffInput = [];
        employees.forEach((employee) => {
          if (employee.id) {
            const eventWithEmployee = { ...newEvent };
            eventWithEmployee.employee = parseInt(employee.id);
            timeOffInput.push(eventWithEmployee);
          }
        });

        batchManagerCreateTimeOff({
          variables: {
            input: timeOffInput,
          },
        });
      } else {
        newEvent.employee = parseInt(employee.id);
        managerCreateTimeOff({ variables: { ...newEvent } });
      }
    } else {
      newEvent.employee = parseInt(user.id);
      createTimeOff({ variables: { ...newEvent } });
    }
  };

  //check to see if user already has a request scheduled that date
  const checkInvalid = (date) => {
    if (employee !== user.id) {
      return false;
    } else {
      const formatted = format(date, "MM/dd/yyyy");
      return invalidDates.includes(formatted);
    }
  };

  const eligibleToSave = Boolean(
    !checkInvalid(startDate) &&
      !checkInvalid(endDate) &&
      !endDate < startDate &&
      type != null &&
      employee,
  );

  const handleUpdateStart = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid || employee !== user.id) {
        setStartDate(date);
        setStartError("");
        if (date > endDate) {
          setEndDate(date);
        }
      } else {
        setStartError(
          "You must choose a date that does not already have a request",
        );
      }
    }
  };

  const handleUpdateEnd = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid || employee !== user.id) {
        setEndDate(date);
        setEndError("");
      } else {
        setEndError(
          "You must choose a date that does not already have a request",
        );
      }
    }
  };

  const handleWorkHoursChange = (event) => {
    setWorkHours(event.target.value);
  };

  //if isAllDay is true, set start and end times to empty strings
  //if isAllDay is false, set end date to equal start date
  const handleIsAllDayChange = (e) => {
    setIsAllDay(e.target.checked);
    !e.target.checked && setEndDate(startDate);
    e.target.checked && setStartTime("");
    e.target.checked && setEndTime("");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Add Calendar Event</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
              data-testid="closeTimeOffFormShift"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isAllDay}
                onChange={handleIsAllDayChange}
                name="isAllDay"
                color={isAllDay ? "primary" : "secondary"}
                data-testid="allDaySwitchTimeOffFormShift"
              />
            }
            label="All Day"
          />
        </Grid>
        {managerAccess && (
          <Grid item data-testid={props.datatestid}>
            <EmployeeSearchBox
              options={employees}
              name="employeesToSelect"
              id="employeesToSelect"
              placeholder="Filter by Employees"
              setEmployee={setEmployee}
              val={employee}
              datatestid="searchEmployeeTimeOffFormShift"
            />

            {/* <Select
              id="employee"
              name="employee"
              variant="outlined"
              value={employee}
              className={classes.employeeInput}
              onChange={(e) => setEmployee(e.target.value)}
            >
              <MenuItem value="All">All Employees</MenuItem>
              {employees
                ? employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name}
                    </MenuItem>
                  ))
                : null}
            </Select> */}
          </Grid>
        )}
        <Grid item container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <InputLabel htmlFor="start-date">
              <Typography variant="h6">Start Date:</Typography>
            </InputLabel>
            <div data-testid="startDateTimeOffFormShift">
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="start-date"
                shouldDisableDate={checkInvalid}
                minDate={minimumDate}
                minDateMessage={
                  managerAccess || !scheduleEndDate
                    ? "Date should not be before today"
                    : "Date should not be before schedule ends"
                }
                value={startDate}
                onChange={handleUpdateStart}
                className={classes.input}
                renderInput={(props) => <TextField {...props} />}
              />
            </div>

            {startError && (
              <Typography variant="body2" className={classes.dateError}>
                {startError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <InputLabel htmlFor="end-date">
              <Typography variant="h6">End Date:</Typography>
            </InputLabel>
            <div data-testid="endDateTimeOffFormShift">
              {" "}
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="end-date"
                shouldDisableDate={checkInvalid}
                minDate={startDate}
                minDateMessage="Date should not be before start date"
                value={endDate}
                onChange={handleUpdateEnd}
                className={classes.input}
                renderInput={(props) => <TextField {...props} />}
              />
            </div>

            {endError && (
              <Typography variant="body2" className={classes.dateError}>
                {endError}
              </Typography>
            )}
          </Grid>
        </Grid>
        {!isAllDay && (
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <InputLabel htmlFor="start-time">
                <Typography variant="h6">Start Time:</Typography>
              </InputLabel>
              <TextField
                id={"start-time"}
                name={"startTime"}
                variant="outlined"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                type="time"
                disabled={isAllDay}
                className={classes.timeField}
                data-testid="startTimeTimeOffFormShift"
              />
            </Grid>
            <Grid item>
              <InputLabel htmlFor="end-time">
                <Typography variant="h6">End Time:</Typography>
              </InputLabel>
              <TextField
                id={"end-time"}
                name={"endTime"}
                variant="outlined"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                type="time"
                disabled={isAllDay}
                className={classes.timeField}
                data-testid="endTimeTimeOffFormShift"
              />
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="type">
              <Typography variant="h6">Type of Request:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              id="type"
              name="type"
              variant="outlined"
              type="number"
              value={type}
              className={classes.input}
              onChange={(e) => setType(e.target.value)}
              data-testid="requestsTypeTimeOffFormShift"
            >
              {availabilityTypes && availabilityTypes.length > 0
                ? availabilityTypes.map((type) => (
                    <MenuItem key={type.name + type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="work-hours">
              <Typography variant="h6">Hours Used:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <TextField
              id="work-hours"
              name="work-hours"
              variant="outlined"
              type="number"
              value={workHours}
              onChange={handleWorkHoursChange}
              data-testid="workhoursTimeOffFormShift"
            />
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="comment">
            <Typography variant="h6">Comment:</Typography>
          </InputLabel>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            value={comment}
            className={classes.wideInput}
            onChange={(e) => setComment(e.target.value)}
            data-testid="commentTimeOffFormShift"
          />
        </Grid>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!eligibleToSave}
              data-testid="saveTimeOffFormShift"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

export default TimeOffRequestForm;
