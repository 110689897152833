import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  InputLabel,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import { findEmployeesToSwitch } from "../../helpers/shiftSwitch";
// import EmpSelectEmployeesForm from "./EmpSelectEmployeesForm";
import EmpSelectEmployeesandDates from "./EmpSelectEmployeesandDates";
import { add, format, isSameDay } from "date-fns";
import {
  GET_SHIFT_ASSIGNMENT,
  RESCHEDULE_SWAP_OPTIONS,
  ACCEPT_OPTION,
  CREATE_RESCHEDULE_OPTION,
} from "../../api/gqlQueries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { userVar } from "../../cache";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    width: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: "#8CADE1",
    fontWeight: 700,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  select: {
    width: 273,
    marginTop: theme.spacing(1),
  },
}));

const EmpShiftSwitchRequestForm = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    closeDialog,
    shiftSwitchBuffer,
    notifyDevelopers,
    environment,
    selectedOffice,
    selectedShift,
  } = props;
  const user = userVar();

  //get user's skills
  const userJobTypes = new Set(
    selectedShift.employee.skillSet.map((skill) => skill.name),
  );

  const getSelectedDateShifts = (d) => {
    let uniqueShifts = [];
    let eventsForSelectedDate = ref.current
      .getEvents(new Date(d), add(new Date(d), { days: 1 }))
      .filter((e) => e.eventType === "SHIFTASSIGNMENT");
    eventsForSelectedDate &&
      eventsForSelectedDate.length > 0 &&
      eventsForSelectedDate.map((e) => {
        if (!uniqueShifts.find((x) => x.shiftId === e.shiftId)) {
          uniqueShifts.push(e);
        }
      });
    return uniqueShifts;
  };

  // get minimum Date for which the employee can create a shift swicth. This is an office setting
  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  const [acceptOption] = useMutation(ACCEPT_OPTION, {
    onCompleted(data) {
      toastShow("Employee Shift Switch successful", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );

      notifyDevelopers({
        variables: {
          message:
            "Error on ACCEPT_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      acceptOption({
        variables: {
          acceptor: parseInt(user.id),
          option: parseInt(data.createRescheduleOption.option.id),
        },
      });
    },
    onError(error) {
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const rescheduleSwapOptions = useQuery(RESCHEDULE_SWAP_OPTIONS, {
    variables: {
      shiftAssignments: parseInt(selectedShift.employee.shiftAssignmentId),
    },
    onCompleted(data) {
      if (data.rescheduleSwaps.length > 0) {
        const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
          const past = swap.rescheduleindividualSet[0].rescheduleactionSet.find(
            (action) => new Date(action.shift.start) < minimumDate,
          );

          return swap.status === "OPTION" && !past;
        });
        setRescheduleOptionIds([]);
        setRescheduleSwapAddOptions(swapsWithOptionStatus);
        setManualEntry(false);
      } else {
        setRescheduleSwapAddOptions([]);
      }
    },
    onError(error) {
      console.log(error);
      toastShow(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [manualEntry, setManualEntry] = useState(false);
  const [error, setError] = useState("");
  const [desiredDateError, setDesiredDateError] = useState("");
  const currentDate = selectedShift.start;
  const [desiredDate, setDesiredDate] = useState();

  const [desiredDateEmployees, setDesiredDateEmployees] = useState([]);
  const [eligibleEmployees, setEligibleEmployees] = useState([]);
  const [employeeToNotify, setEmployeeToNotify] = useState("select");

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState([]);
  const [rescheduleOptionIds, setRescheduleOptionIds] = useState([]);

  const [shift, setShift] = useState("Select Shift");
  const [shiftNames, SetShiftNames] = useState(
    getSelectedDateShifts(minimumDate),
  );

  const toggleManualEntry = () => setManualEntry(!manualEntry);

  const formattedUserScheduleDates = ref.current.eventsData
    .filter((e) => e.employee.id === user.id)
    .map((event) => format(new Date(event.start), "MM/dd/yyyy"));

  const handleShiftChange = (event) => {
    const newShift = event.target.value;
    setShift(newShift);
    const newDesiredEmployees = newShift.participants;

    if (newDesiredEmployees) {
      setDesiredDateEmployees(newDesiredEmployees);
      setEligibleEmployees(newDesiredEmployees);
    } else {
      setError("Invalid date or shift selection");
    }
  };

  const handleDesiredDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      if (!checkSecondDateInvalid(date)) {
        setDesiredDate(date);
        setEmployeeToNotify("select");

        const newShifts = getSelectedDateShifts(date);
        SetShiftNames(newShifts);
        setError("");
        setDesiredDateError("");
      } else {
        setDesiredDateEmployees([]);
        setEligibleEmployees([]);
        setEmployeeToNotify("select");
        setDesiredDateError(
          "You must choose a desired date that you are not working",
        );
        setDesiredDate(date);
      }
    }
  };

  const handleSubmit = () => {
    if (rescheduleOptionIds.length > 0) {
      rescheduleOptionIds.forEach((id) => {
        acceptOption({
          variables: {
            acceptor: parseInt(user.id),
            option: parseInt(id),
          },
        });
      });
    } else if (employeeToNotify !== "select") {
      //manually create swap options
      const individuals = [
        {
          employee: parseInt(employeeToNotify),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "ADD",
              shift: selectedShift.shiftId,
            },
            {
              actionType: "DROP",
              shift: shift.shiftId,
            },
          ],
        },
        {
          employee: parseInt(user.id),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "DROP",
              shift: selectedShift.shiftId,
            },
            {
              actionType: "ADD",
              shift: shift.shiftId,
            },
          ],
        },
      ];

      createSwapOption({
        variables: {
          optionType: "SWAP",
          individuals: individuals,
          userGenerated: true,
        },
      });
    } else {
      setError("You must choose an employee to notify");
    }
  };

  const checkSecondDateInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return formattedUserScheduleDates.includes(formatted);
  };

  const eligibleToSave = Boolean(
    (rescheduleOptionIds.length > 0 || employeeToNotify !== "select") &&
      currentDate > minimumDate,
  );

  const eligibleEmployeesPresent =
    eligibleEmployees && eligibleEmployees.length > 0;
  const rescheduleSwapOptionsPresent = rescheduleSwapAddOptions.length > 0;

  if (rescheduleSwapOptions.loading) {
    return <CircularProgress />;
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid>
          <Grid container direction="row">
            <Grid item>
              <Typography variant="h4">Current Shift Information</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Employee Name</Typography>
              <Typography variant="body1">
                {selectedShift.employee.firstName +
                  " " +
                  selectedShift.employee.lastName}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Shift time and date</Typography>
              <Typography variant="body1">
                {format(selectedShift.start, "MMMM dd yyyy hh:mm a") +
                  " to " +
                  format(selectedShift.end, "MMMM dd yyyy hh:mm a")}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Location</Typography>
              <Typography variant="body1">
                {selectedShift.officeName}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <Typography variant="h6">Skill</Typography>
              <Typography variant="body1">
                {userJobTypes.map((e) => e).join(", ")}
              </Typography>
            </Grid>
          </Grid>
          {(rescheduleSwapOptionsPresent ||
            (manualEntry && eligibleEmployeesPresent)) && (
            <Grid
              item
              container
              justifyContent="flex-start"
              style={{ marginTop: 15 }}
            >
              <RescheduleOptionsKey type="switch" />
            </Grid>
          )}
          <Grid container style={{ marginTop: 16 }}>
            <Typography variant="h6">Dates to Switch</Typography>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={2}>
            {manualEntry && (
              <Grid item>
                <InputLabel htmlFor="desired-date">
                  <Typography variant="h6">To</Typography>
                </InputLabel>
                <DatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="desired-date"
                  shouldDisableDate={checkSecondDateInvalid}
                  value={desiredDate}
                  onChange={handleDesiredDateChange}
                  minDate={minimumDate}
                  className={classes.input}
                  renderInput={(props) => <TextField {...props} />}
                  data-testid="desiredDateEmpShiftSwitchForm"
                />
                {desiredDateError && (
                  <Typography variant="body2" className={classes.dateError}>
                    {desiredDateError}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          {manualEntry && (
            <Grid item style={{ marginTop: 16 }}>
              <InputLabel id="shift-select-label" shrink={false}>
                <Typography variant="h6">Shift</Typography>
              </InputLabel>
              <Select
                labelId="shift-select-label"
                id="shift-select"
                variant="outlined"
                value={shift}
                onChange={handleShiftChange}
                data-testid="selectShiftSwitchMangShiftSwitch"
              >
                <MenuItem key="Select Shift" value="Select Shift">
                  Select Shift
                </MenuItem>
                {shiftNames.map((shiftName) => (
                  <MenuItem key={shiftName.shiftId} value={shiftName}>
                    {format(new Date(shiftName.start), "MMMM dd yy hh:mm a") +
                      " to " +
                      format(new Date(shiftName.end), "MMMM dd yy hh:mm a")}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid container style={{ marginTop: 16 }}>
            {(rescheduleSwapOptionsPresent ||
              (manualEntry && eligibleEmployeesPresent)) && (
              <Typography className={classes.spaceBelow}>
                Select the employees you would like to send the request to:
              </Typography>
            )}
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="space-evenly"
              spacing={1}
            >
              {error && (
                <Grid item>
                  <Typography
                    className={classes.error}
                    data-testid="errorEmpShiftSwitchForm"
                  >
                    {error}
                  </Typography>
                </Grid>
              )}
              {manualEntry && (
                <Select
                  id="select-employee"
                  variant="outlined"
                  value={employeeToNotify}
                  onChange={(e) => setEmployeeToNotify(e.target.value)}
                  className={classes.select}
                  data-testid="employeeToNotifyShiftSwitchForm"
                >
                  <MenuItem value="select">Select Employee</MenuItem>
                  {desiredDateEmployees && desiredDateEmployees.length > 0 ? (
                    desiredDateEmployees.map((employee, index) => (
                      <MenuItem
                        key={`${employee.lastName}-${index}`}
                        value={employee.employeeId}
                      >
                        <Typography>
                          {employee.firstName + " " + employee.lastName}
                          <span style={{ fontSize: 15, fontWeight: 500 }}>
                            {format(new Date(shift.start), " dd MMM ") +
                              format(new Date(shift.start), "HH:mm") +
                              "-" +
                              format(new Date(shift.end), "HH:mm")}
                          </span>
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={"none"}>
                      <Typography>No Eligible Employees</Typography>
                    </MenuItem>
                  )}
                </Select>
              )}
              {rescheduleSwapOptionsPresent && !manualEntry && (
                <EmpSelectEmployeesandDates
                  rescheduleOptions={rescheduleSwapAddOptions}
                  setRescheduleOptionIds={setRescheduleOptionIds}
                  setError={setError}
                />
              )}
              {!rescheduleSwapOptionsPresent && !eligibleEmployeesPresent && (
                <Grid item>
                  <Typography>
                    There are no shift switch options available. Please try
                    different dates.
                  </Typography>
                </Grid>
              )}
              {!rescheduleSwapOptionsPresent && !manualEntry && (
                <Grid item>
                  <Typography>
                    We could not find any algorithm-generated shift switch
                    options for this date.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{
              marginTop: 20,
              zIndex: 4,
              position: "absolute",
              bottom: 25,
            }}
          >
            {/* <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleManualEntry}
                data-testid="viewOptionButtonShiftSwitchForm"
              >
                {manualEntry ? "View Generated Options" : "View Manual Options"}
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!eligibleToSave}
                style={{ width: 169, height: 39, marginRight: 60 }}
                data-testid="submitShiftSwitchForm"
              >
                Switch Shifts
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    );
  }
});

export default EmpShiftSwitchRequestForm;
