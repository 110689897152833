import { format } from "date-fns";
import OfficeDisplayOrder from "./OfficeDisplayOrder.json";
import SkillColors from "./SkillColors.json";
/* The scheduler we are using is Syncfusion. Syncfusion accepts props in the format
     {
      Id: unique ID for the event,
      StartTime: event start time,
      EndTime: event end time,\
      IsAllDay: indicates of event is all day
    };
    This helper method formats the shifts retrieved from database from SQL_SHIFT_ASSIGNMENTS graphql query and returns data in the format accepted by Syncfusion.
    Time-Off Soft-Time off and logged in user's events are formatted in this helper.
*/

export const formatOnCalls = (onCalls, userId) => {
  return (
    onCalls &&
    onCalls.map((onCall) => {
      const name = onCall.employee.firstName + " " + onCall.employee.lastName;
      const personal = parseInt(onCall.employee.id) === parseInt(userId);
      const start = new Date(onCall.start);
      const stringStart = start.toISOString();
      const end = new Date(onCall.end);
      const stringEnd = end.toISOString();

      const newOnCall = {
        eventId: onCall.id,
        eventTitle: `${name}`,
        type: "OnCall",
        category: personal ? "personal" : "employee",
        employeeIds: [onCall.employee.id],
        shiftId: "OnCall",
        office: "OnCall",
        officeName: onCall.office.name,
        start: stringStart,
        end: stringEnd,
        calendars: [
          "TimelineWeek",
          "Month",
          "officeTimeline",
          "Day",
          "employeeTimeline",
        ],
        shiftType: "OnCall",
      };
      return newOnCall;
    })
  );
};

export const orderShifts = (shifts) => {
  const shiftNames = [];
  if (shifts.length > 0) {
    let formatted = [];
    shifts.forEach((shift) => {
      let parentShift = shifts.find(
        (e) => parseInt(e.id) === parseInt(shift.parentShiftId),
      );
      const start = new Date(shift.start);
      const stringStart = start.toISOString();
      const end = new Date(shift.end);
      const stringEnd = end.toISOString();
      const parentShiftStart = parentShift && new Date(parentShift.start);
      const parentShiftEnd = parentShift && new Date(parentShift.end);
      const formattedStart =
        window.SHOW_MILITARY_TIME === false ||
        window.SHOW_MILITARY_TIME === "false"
          ? format(new Date(start), "hh:mm")
          : format(new Date(start), "HH:mm");
      const formattedEnd =
        window.SHOW_MILITARY_TIME === false ||
        window.SHOW_MILITARY_TIME === "false"
          ? format(new Date(end), "hh:mm")
          : format(new Date(end), "HH:mm");
      const parentShiftFormattedStart =
        parentShiftStart && format(parentShiftStart, "HH:mm");
      const parentShiftFormattedEnd =
        parentShiftEnd && format(parentShiftEnd, "HH:mm");

      const monthViewEnd =
        shift.shiftTypeName === "Night" ||
        (shift.shiftTypeName === "Swing" && parseInt(format(end, "HH")) < 12)
          ? format(start, "yyyy-MM-dd") + "T" + "23:59:00"
          : stringEnd;
      const title = `${formattedStart} - ${formattedEnd}`;
      const parentShiftTitle = `${parentShiftFormattedStart} - ${parentShiftFormattedEnd}`;
      let nameOfTheShift = shiftNames.find(
        (e) =>
          e.title === title &&
          e.office === shift.officeName &&
          e.description === shift.description,
      );
      if (nameOfTheShift === "undefined" || !nameOfTheShift) {
        shift.parentShiftId === null &&
          shiftNames.push({
            title: title,
            office: shift.officeName,
            officeName: shift.officeName,
            description: shift.description,
            startHour: start,
            endHour: end,
            displayOrder: OfficeDisplayOrder.offices.find(
              (e) => e.name === shift.officeName,
            )
              ? OfficeDisplayOrder.offices.find(
                  (e) => e.name === shift.officeName,
                ).displayPriority
              : "",
          });
      }
      const shiftAssignments = shift.shiftassignmentSet;

      const participants = shiftAssignments.map((shiftAssignment) => {
        const float =
          shiftAssignment.employee.offices &&
          shiftAssignment.employee.offices.find((e) => e.floatStatus);
        return {
          ...shiftAssignment,
          isFromFloatOffice: float ? true : false,
        };
      });

      const participantIds = participants
        .map((participant) => participant.employeeId)
        .filter(Boolean);

      //
      formatted.push({
        id: shift.id,
        eventId: shift.id,
        idOfShift: shift.id,
        start: stringStart,
        end: stringEnd,
        monthViewEnd: monthViewEnd,
        participants: [...participants],
        eventTitle: title,
        employeeIds: [...participantIds],
        shiftId: title + " " + shift.officeName,
        office: shift.officeName,
        officeName: shift.officeName,
        calendars: ["Month", "Day", "employeeTimeline"],
        description: shift.description,
        shiftType: shift.shiftTypeName,
        parentShiftId: shift.parentShiftId,
        rootShiftId: shift.rootShiftId,
        workHours: shift.workHours,
      });
      if (participants.length > 0) {
        participants.forEach((participant) => {
          let uniqueSkills = [];
          let skillSet =
            participant.skillSet &&
            participant.skillSet.map((e) => {
              return {
                id: e.id,
                skillId: e.skillId,
                name: e.name,
                isActive: e.isActive,
              };
            });
          let allSkillNames =
            skillSet && skillSet.length > 0 && skillSet.map((e) => e.name);
          let color =
            allSkillNames && allSkillNames.length > 0
              ? SkillColors.skills.find((e) => allSkillNames.includes(e.name))
                ? SkillColors.skills.find((e) => allSkillNames.includes(e.name))
                    .color
                : "none"
              : "none";
          formatted.push({
            id: participant.id,
            eventId: shift.id,
            idOfShift: shift.id,
            shiftName: title,
            start: stringStart,
            end: stringEnd,
            monthViewEnd: monthViewEnd,
            participant: participant,
            employeeIds: [participant.employeeId],
            eventTitle: `${participant.employee}`,
            shiftId:
              shift.parentShiftId != null
                ? parentShiftTitle + " " + shift.officeName
                : title + " " + shift.officeName,
            calendars: ["TimelineWeek", "Month", "officeTimeline"],
            originalParticipants: [...participants],
            skills: skillSet,
            skillNames:
              participant.skillSet &&
              participant.skillSet.length > 0 &&
              participant.skillSet
                .map(
                  (e) =>
                    !uniqueSkills.find((s) => s === e.name) &&
                    uniqueSkills.push(e.name) &&
                    e.name != "APP" &&
                    e.name,
                )
                .filter(Boolean),
            color: color,
            office: shift.officeName,
            officeName: shift.officeName,
            description: shift.description,
            shiftType: shift.shiftTypeName,
            parentShiftId: shift.parentShiftId,
            rootShiftId: shift.rootShiftId,
            title: title,
            workHours: participant.workHours,
            shiftAssignmentId: participant.id,
          });
        });
      } else {
        formatted.push({
          idOfShift: shift.id,
          start: stringStart,
          end: stringEnd,
          eventTitle: title,
          calendars: ["TimelineWeek", "Month", "officeTimeline"],
          office: shift.officeName,
          officeName: shift.officeName,
          employeeIds: [],
          shiftWithNoparticipants: true,
          workHours: shift.workHours,
          parentShiftId: shift.parentShiftId,
          rootShiftId: shift.rootShiftId,
          shiftType: shift.shiftTypeName,
        });
      }
    });
    formatted.sort((a, b) => new Date(a.start) - new Date(b.start));
    return { formatted, shiftNames };
  } else {
    return {
      formatted: [],
      shiftNames: [],
    };
  }
};

export const formatSoftRequests = (requests, userId) => {
  return (
    requests &&
    requests.length > 0 &&
    requests.map((request) => {
      const highPriority = request.value > 5;
      return {
        eventId: request.id,
        eventTitle: "Soft Time Off",
        start: new Date(`${request.date}T08:00:00`),
        end: new Date(`${request.date}T09:00:00`),
        type: "softRequest",
        highPriority,
        employeeIds: [userId],
        isAllDay: true,
        shiftId: "Requests",
      };
    })
  );
};

export const formatTimeOff = (requests, userId) => {
  return (
    requests &&
    requests.map((request) => {
      const name = request.employee.firstName + " " + request.employee.lastName;
      const personal = parseInt(request.employee.id) === parseInt(userId);
      const newRequest = {
        eventId: request.id,
        eventTitle: `${name} - ${request.type.name}`,
        type: "timeOff",
        status: request.approvedby ? "approved" : "pending",
        category: personal ? "personal" : "employee",
        employeeIds: [request.employee.id],
        approvedby: request.approvedby,
        deniedText: request.deniedText,
        userCancelled: request.userCancelled,
        workHours: request.workHours,
        typeId: request.type.id,
        comment: request.comment,
        shiftId: "Requests",
        office: "Requests",
        officeName: request.office.name,
        createdAt: request.createdAt,
        color:
          window.DISPLAY_COLORS_ON_TIMEOFFS === true ||
          window.DISPLAY_COLORS_ON_TIMEOFFS === "true"
            ? request.type.name === "PTO"
              ? "#FFFF00"
              : "none"
            : "none",
      };

      if (request.startTime && request.endTime) {
        newRequest.start = new Date(`${request.firstday} ${request.startTime}`);
        newRequest.end = new Date(`${request.lastday} ${request.endTime}`);
        newRequest.isAllDay = false;
      } else {
        newRequest.start = new Date(`${request.firstday}T08:00:00`);
        newRequest.end = new Date(`${request.lastday}T08:00:00`);
        newRequest.isAllDay = true;
      }
      return newRequest;
    })
  );
};
